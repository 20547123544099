<template>
  <models
    :showmodel="showAutoRenew"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeAutoRenew"
    class="ifeditename"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>自动续费：</span></div>
        </el-col>
        <el-col :span="18">
          <el-select
            size="small"
            v-model="autoRenew"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="tips">Tips：矩阵版云手机的自动续费时间将统一更新</div>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showAutoRenew: {
      type: Boolean,
    },
    row: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
    },
     matrixGroupId: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      autoRenew: this.row.AutoRenew,
      options: [
        { value: 0, label: "不开启" },
        // {value: 7, label: '按7天'},
        { value: 30, label: "按月" },
        { value: 90, label: "按季度" },
        { value: 180, label: "按半年" },
        { value: 360, label: "按年" },
      ],
    };
  },
  watch: {
    row: function (val) {
      this.autoRenew = val.AutoRenew;
      this.row = val;
    },

    matrixGroupId: function (val) {
      this.matrixGroupId = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    confirmedit() {
      var phoneIds = [];
      this.row.forEach((item) => {
        phoneIds.push(item.Id);
      });

      this.$store
        .dispatch("group/setphoneautorenewmatrix", {
          ChargeId: this.autoRenew,
          MatrixGroupId:this.matrixGroupId,
          PhoneIds: phoneIds,
        })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("修改成功");
            this.closeAutoRenew();
          } else {
            this.$message.error(res.Message || "修改失败");
            this.closeAutoRenew();
          }
        });

      console.log(this.row);
    },
    closeAutoRenew() {
      this.$emit("closeAutoRenew");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }

  .tips {
    margin: 36px 0 0 14px;
    color: #a1a1a1;
    font-size: 13px;
  }
}
</style>