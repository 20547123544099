<template>
  <drawer
    size="40%"
    :row="listrow"
    :drawer="drawershow"
    @handleclose="handleclose"
    @handleopen="handleopen"
  >
    <div class="drawer-item">
      <p class="title-wrap">
        <i class="iconfont icon-gouwucheman titleicon"></i
        ><span class="title">续费云手机</span>
      </p>
      <el-table
        :data="[listrow]"
        class="eltable"
        size="small"
        :header-cell-style="headercellstyle"
      >
        <template v-for="(item, index) in columns">
          <el-table-column
            :key="`index_${index}`"
            :prop="item.prop"
            :formatter="item.formatter"
            :label="item.label"
            :align="item.align"
            :min-width="item.width"
            :header-align="item.headerAlign"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <div class="drawer-item">
      <p class="title-wrap">
        <i class="iconfont icon-zhongbiao titleicon"></i
        ><span class="title">时长选择</span>
      </p>
      <div class="select-sc">
        <div class="select-item">
          <div @click="selectsc(1)" :class="['day', { 'sc-active': sc == 1 }]">
            <span>天付</span>
          </div>
          <div
            @click="selectsc(30)"
            :class="['month', { 'sc-active': sc == 30 }]"
          >
            <span>月付</span>
          </div>
          <div
            @click="selectsc(90)"
            :class="['season', { 'sc-active': sc == 90 }]"
          >
            <span>季付</span>
          </div>
          <div
            @click="selectsc(180)"
            :class="['halfyear', { 'sc-active': sc == 180 }]"
          >
            <span>半年付</span>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-item">
      <p class="title-wrap">
        <i class="iconfont icon-jiaofeizhangdan titleicon"></i
        ><span class="title">付费信息</span>
      </p>
    </div>
    <div class="ffxx">
      <div class="ff-detail">
        <span class="dtcontent">云手机规格</span
        ><span class="dttext">{{ detailform.guige }}</span>
      </div>
      <div class="ff-detail">
        <span class="dtcontent">目标区域</span
        ><span class="dttext">{{ detailform.region }}</span>
      </div>
      <div class="ff-detail">
        <span class="dtcontent">购买数量</span
        ><span class="dttext">{{ detailform.num }}</span>
      </div>
      <div class="ff-detail">
        <span class="dtcontent">购买时长</span
        ><span class="dttext">{{ detailform.sc }}</span>
      </div>
      <div class="ff-detail">
        <span class="dtprice">合计费用</span
        ><span class="dtprice">{{ detailform.total }} 元</span>
      </div>
      <el-radio-group class="payradio" v-model="payradio">
        <el-radio :label="1"
          ><i class="iconfont icon-weixinzhifu"></i>微信支付</el-radio
        >
        <el-radio :label="2" disabled
          ><i class="iconfont icon-zhifubaozhifu"></i>支付宝</el-radio
        >
      </el-radio-group>
      <el-button
        @click="navorderconfirm"
        :loading="loading"
        class="paybtn"
        size="small"
        type="primary"
        >立即支付</el-button
      >
    </div>
  </drawer>
</template>
<script>
import Drawer from "../../../components/Drawer";
import ComTable from "../../../components/Table";
import navigation from "../../../utils/navigation";
export default {
  props: {
    row: {
      type: Object,
    },
    drawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      columns: [
        {
          prop: "Name",
          label: "云手机名称",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "ProductId",
          label: "云手机规格",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            if (cellValue == 8) {
              return "UPhone Mini";
            }
            if (cellValue == 9) {
              return "UPhone X";
            }
            if (cellValue == 12) {
              return "UPhone Pro";
            }
          },
        },
        {
          prop: "IpRegion",
          label: "IP地域",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
          formatter: (row, column, cellValue, index) => {
            if (cellValue == 1) {
              return "北京";
            }
            if (cellValue == 2) {
              return "上海";
            }
            if (cellValue == 3) {
              return "广州";
            }
          },
        },
      ],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      listrow: this.row,
      drawershow: this.drawer,
      sc: 1,
      detailform: {
        region: "",
        guige: "",
        num: "1",
        sc: "1天",
        total: "--",
      },
      payradio: 1,
      IpRegionPrice: [],
      ProductPrice: [],
      loading: false,
    };
  },
  watch: {
    row: function (val) {
      this.listrow = val;
      this.detailform.region =
        val.IpRegion == 1 ? "北京" : val.IpRegion == 2 ? "上海" : "广州";
      this.detailform.guige =
        val.ProductId == 8 ? "Mini" : val.ProductId == 9 ? "X" : "Pro";
    },
    drawer: function (val) {
      this.drawershow = val;
    },
    sc: function (val) {
      this.sc = val;
      this.gettotleprice();
    },
  },
  components: { Drawer, ComTable },
  methods: {
    handleclose() {
      this.$emit("closedrawer");
    },
    getregionlist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("pay/getregionlist").then((res) => {
          if (res.RetCode == 0) {
            res.IpRegions.forEach((item, index) => {
              if (item.Id == this.row.IpRegion) {
                resolve(item.Prices);
              }
            });
          }
        });
      });
    },
    getproductlist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("pay/getproductlist").then((res) => {
          if (res.RetCode == 0) {
            res.Products.forEach((item, index) => {
              if (item.Id == this.row.ProductId) {
                resolve(item.Prices);
              }
            });
          }
        });
      });
    },
    handleopen() {
      Promise.all([this.getregionlist(), this.getproductlist()]).then(
        ([IpRegionPrice, ProductPrice]) => {
          this.IpRegionPrice = IpRegionPrice;
          this.ProductPrice = ProductPrice;
          this.gettotleprice();
        }
      );
    },
    selectsc(val) {
      this.sc = val;
      // this.payform.ChargeId = val;
      switch (val) {
        case 1:
          this.detailform.sc = "1天";
          break;
        case 30:
          this.detailform.sc = "1个月";
          break;
        case 90:
          this.detailform.sc = "1季度";
          break;
        case 180:
          this.detailform.sc = "半年";
          break;
      }
    },
    gettotleprice() {
      let total = 0;
      this.IpRegionPrice.forEach((item, index) => {
        if (item.ChargeId == this.sc) {
          total = total + item.Free;
        }
      });
      this.ProductPrice.forEach((item, index) => {
        if (item.ChargeId == this.sc) {
          total = total + item.Free;
        }
      });
      this.detailform.total = total;
    },
    navorderconfirm() {
      this.loading = true;
      let data = {
        ResourceId: [this.listrow.Id],
        ChargeId: this.sc,
        ResourceType: "Phone",
      };
      this.$store.dispatch("pay/renewphoneorder", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.loading = false;
          navigation.navigateTo({
            url: "renewconfirm",
            params: {
              TotalAmount: res.TotalAmount,
              TradeNo: res.TradeNo,
              detail: this.detailform,
            },
          });
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>