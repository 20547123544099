<template>
  <div>
    <el-dialog
      @opened="initvideo"
      :before-close="closemodel"
      :visible.sync="showmodel"
      :close-on-click-modal="false"
      :modal="false"
      v-dialogDrag
      ref="dialog"
      class="my-info-dialog"
    >
      <div style="-webkit-user-select: none" slot="title" class="dialog-title">
        <span>{{ data.Id }}</span>
        <span :style="classObje(content)">{{ content }}</span>
        <el-popover
          placement="top-start"
          width="200"
          trigger="click"
          content="为了更好模拟真实手机，我们需要在云手机创建之后安装若干app，这个过程大概需要5~8分钟
，期间可以照常操作云手机，不过可能会出现操作卡顿情况。"
        >
          <el-button
            class="explain"
            v-show="isexplain"
            slot="reference"
            icon="el-icon-question"
            type="text"
            size="medium"
          >
          </el-button>
        </el-popover>

        <span
          v-if="usecamera == false"
          @click="changecamera"
          class="iconfont icon-shexiangtou_guanbi camera"
        ></span>
        <span
          v-else
          @click="changecamera"
          class="iconfont icon-shexiangtou camera"
        ></span>
        <i
          v-if="usemicrophone == true"
          @click="changemicro"
          class="el-icon-microphone microphone"
        ></i>
        <i
          v-else
          @click="changemicro"
          class="el-icon-turn-off-microphone microphone"
        ></i>
        <i @click="zoomin" class="el-icon-zoom-in zoomin"></i>
        <i @click="zoomout" class="el-icon-zoom-out zoomout"></i>
      </div>
      <div ref="modelbody" class="modelbody">
        <div
          v-loading="loading"
          :element-loading-text="loadingtext"
          element-loading-spinner="el-icon-loading"
          element-loading-background="transparent"
          class="c-video"
          ref="cvideo"
        >
          <el-result
            v-if="isresult"
            icon="warning"
            :title="loadingtext"
            subTitle="是否强制连接"
          >
            <template slot="extra">
              <el-button
                class="el-button-style"
                @click="forceconnect"
                type="primary"
                size="mini"
                >确定</el-button
              >
            </template>
          </el-result>
          <el-result
            v-if="isduplicate"
            icon="warning"
            :title="duplicatetext"
            subTitle="该设备正在其他网页尝试连接"
          >
            <!-- <template slot="extra">
              <el-button @click="forceconnect" type="primary" size="mini"
                >确定</el-button
              >
            </template> -->
          </el-result>
          <!-- <i v-if="icestatus == 'connected'" class="el-icon-success status"></i>
          <i
            v-if="icestatus == 'disconnected'"
            class="el-icon-warning warning-status"
          ></i> -->
          <video
            id="remote-video"
            ref="revideo"
            autoplay
            playsinline
            webkit-playsinline
            @contextmenu.prevent
            @blur="blur"
            @mousedown="mousedown"
            @mouseup="mouseup"
            @mousemove="mousemove"
            @mouseout="mouseout"
            @mousewheel="mousewheel"
            style="width: 100%; height: 100%; pointer-events: pointer"
          ></video>
        </div>

        <div class="setting-area">
          <ul class="setting-ul">
            <li @click="uploadimage">
              <i class="el-icon-upload2"></i><span>上传</span>
            </li>
            <el-popconfirm
              @confirm="phonemenu"
              title="加速会退出所有APP进程,确定要继续吗？"
            >
              <li slot="reference">
                <i class="el-icon-odometer"></i><span>加速</span>
              </li>
            </el-popconfirm>

            <el-popconfirm @confirm="mainrestart" title="确认重启吗?">
              <li slot="reference">
                <i class="el-icon-refresh"></i><span>重启</span>
              </li>
            </el-popconfirm>
            <li @click="changerotate">
              <i class="el-icon-sort"></i><span>旋转</span>
            </li>
            <!--<li @click="showgpsmodel">
              <i class="el-icon-position"></i><span>GPS</span>
            </li>-->
            <!--<el-popconfirm
              @confirm="newphone"
              title="改机是换机型信息但保留手机数据,确定继续吗？"
            >
              <li slot="reference">
                <i class="el-icon-mobile"></i><span>改机</span>
              </li>
            </el-popconfirm>-->
            <el-popconfirm
              @confirm="changephone"
              title="换机是删除手机和数据并重新创建一台,确定继续吗？"
            >
              <li slot="reference">
                <i class="el-icon-mobile-phone"></i><span>换机</span>
              </li>
            </el-popconfirm>
            <el-divider></el-divider>
            <li @click="addvolume">
              <i class="el-icon-circle-plus-outline"></i><span>音量+</span>
            </li>
            <li @click="devolume">
              <i class="el-icon-remove-outline"></i><span>音量-</span>
            </li>
            <li @click="backhome">
              <i class="el-icon-monitor"></i><span>桌面</span>
            </li>
            <li @click="backpage">
              <i class="el-icon-back"></i><span>返回</span>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>

    <!-- <gps-model
      title="设置主控GPS"
      :showsetgps="showsetgps"
      :checkeditem="checkeditem"
      :query="query"
      @closegpsmodel="closegpsmodel"
    ></gps-model> -->
  </div>
</template>
<script>
import ucloudrtcController from "../../../sdk/groupcontroller";
import { sendmessage } from "../../../utils/groupconnection";
import transKeyCode from "../../../utils/transkeycode";
// import GpsModel from "./GpsModel.vue";
export default {
  props: ["showmodel", "data", "query", "iftongbu", "list"],
  data() {
    return {
      poster: require("../../../assets/back.jpg"),
      rtc: null,
      currentRoundTripTime: 0,
      loading: true,
      loadingtext: "连接中",
      duplicatetext: "连接已断开",
      icestatus: "disconnected",
      height: "712",
      rotate: "portrait",
      ratio: "1.7777777777777777",
      showsetgps: false,
      checkeditem: [],
      count: 0,
      content: "正在建立连接...",
      timmer: null,
      isresult: false,
      isduplicate: false,
      kbdown: false,
      zoomwidth: 400,
      isexplain: false,
      usecamera: false,
      usemicrophone: false,
    };
  },
  components: {},
  computed: {
    classObje() {
      return (content) => {
        if (this.content === "云手机app环境准备中") {
          return {
            "font-size": "10px",
            "background-color": "yellow",
            "font-weight": "bolder",
            "margin-left": "5px",
          };
          // return {'font-size':'10px','background-color':'clear','font-weight':'bolder','margin-left': '5px','color':'red'}
        } else {
          // return {'font-size':'10px','background-color':'yellow','font-weight':'bolder','margin-left': '5px'}
          return {
            "font-size": "10px",
            "background-color": "#ffffff",
            "margin-left": "5px",
          };
        }
      };
    },
  },
  mounted() {
    var _this = this;
    //全局监听键盘事件
    document.addEventListener("keydown", _this.onkeydown);
    document.addEventListener("keyup", _this.onkeyup);
  },
  beforeDestroy() {
    this.rtc = null;
  },
  methods: {
    changecamera() {
      this.usecamera = !this.usecamera;
      if (this.usecamera) {
        this.rtc.getCamera();
      } else {
        this.rtc.closeCamera();
      }
    },
    changemicro() {
      this.usemicrophone = !this.usemicrophone;
      if (this.usemicrophone) {
        this.rtc.getMicrophone();
      } else {
        this.rtc.closeMicrophone();
      }
    },
    zoomin() {
      this.zoomwidth = 400;
      this.height = 400 * this.ratio;
      this.$refs.cvideo.style.height = this.height + "px";
      this.$refs.cvideo.style.width = this.zoomwidth + "px";
      this.$refs.modelbody.style.height = this.height + "px";
      this.$refs.modelbody.style.width = this.zoomwidth + 35 + "px";
      var dialog = document.querySelector(".my-info-dialog .el-dialog");
      if (this.rotate == "portrait") {
        dialog.style.height = this.height + 35 + "px";
        dialog.style.width = this.zoomwidth + 35 + "px";
      } else {
        this.$refs.modelbody.style.transform = `translate3d(138px, -138px, 0px) rotate(-90deg)`;
        dialog.style.height = this.zoomwidth + 70 + "px";
        dialog.style.width = this.height + "px";
      }
      console.log("zoomin");
      console.log(this.zoomwidth, this.height);
    },
    zoomout() {
      this.zoomwidth = 300;
      this.height = 300 * this.ratio;
      this.$refs.cvideo.style.height = this.height + "px";
      this.$refs.cvideo.style.width = this.zoomwidth + "px";
      this.$refs.modelbody.style.height = this.height + "px";
      this.$refs.modelbody.style.width = this.zoomwidth + 35 + "px";
      var dialog = document.querySelector(".my-info-dialog .el-dialog");
      if (this.rotate == "portrait") {
        dialog.style.height = this.height + 35 + "px";
        dialog.style.width = this.zoomwidth + 35 + "px";
      } else {
        this.$refs.modelbody.style.transform = `translate3d(99px, -99px, 0px) rotate(-90deg)`;
        dialog.style.height = this.zoomwidth + 70 + "px";
        dialog.style.width = this.height + "px";
      }
      console.log("zoomout");
      console.log(this.zoomwidth, this.height);
    },
    initvideo() {
      var _this = this;
      var loadingParams = {
        Id: this.data.Id,
        mediaConstraints: {
          // audio: true,
          // video: true,
          usecamera: false,
          usemicrophone: false,
          live: true,
        },
        debug: false,
      };
      //建立webrtc连接
      this.creatertcserve(loadingParams, _this);
      setTimeout(() => {
        this.startconnection();
        this.getcurrentround();
      }, 200);
    },
    // ----连接游戏三步骤----

    // Step 1 初始化实例
    creatertcserve(loadingParams, _this) {
      this.rtc = new ucloudrtcController(loadingParams, _this);
      this.rtc.setmaincontrol(true);
    },
    // Step 2 启动云游戏并建立信令服务器连接
    startconnection() {
      this.rtc.startConnection();
      //云游戏连接状态变更回调
      this.rtc.onstatus("devicestatus", (states) => {
        if (states == 500) {
          this.content = "强制连接中";
          this.isresult = false;
          this.restart();
        }
        if (states == 136) {
          this.content = "网络连接断开";
          this.icestatus = "disconnected";
          this.rtc.Hangup();
          this.isduplicate = true;
          return;
        }
        if (
          states == 1000 ||
          states == 1001 ||
          states == 1002 ||
          states == 1003 ||
          states == 1004 ||
          states == 1005 ||
          states == 1006 ||
          states == 1007 ||
          states == 1008 ||
          states == 1009 ||
          states == 1026 ||
          states == 1027 ||
          states == 1028 ||
          states == 1031 ||
          states == 73002 ||
          states == 90012 ||
          states == 1038
        ) {
          if (this.icestatus == "connected") {
            console.log("====主控连接成功====");
          } else {
            this.loading = true;
            this.icestatus = "disconnected";
            this.content = `状态${states}`;
            switch (states) {
              case 1001:
                this.loadingtext = "网络连接错误";
                break;
              case 1003:
                this.loadingtext = "设备已关机";
                break;
              case 1008:
                this.loadingtext = "服务器未响应";
                break;
              case 1026:
                ++this.count;
                if (this.count == 4) {
                  this.loadingtext = "设备被占用";
                  this.isresult = true;
                  this.loading = false;
                  return;
                }
                break;
              case 1027:
                this.loadingtext = "网络连接错误";
                break;
              case 1028:
                this.loadingtext = "网络连接错误";
                break;
              case 1031:
                this.loadingtext = "网络连接错误";
                break;
              case 1038:
                break;
              case 73002:
                this.loadingtext = "设备正在初始化";
                break;
              case 42100:
                this.loadingtext = "请求参数错误";
                break;
              case 50000:
                this.loadingtext = "服务器内部错误";
                break;
              case 90010:
                this.loadingtext = "网络连接错误";
                break;
              case 90011:
                this.loadingtext = "网络连接错误";
                break;
              case 90012:
                this.loadingtext = "网络连接错误";
                break;
              case 90013:
                this.loadingtext = "网络连接错误";
                break;
              default:
                this.loadingtext = "网络连接错误";
                break;
            }
            this.restart();
          }
        }
      });
      //p2p连接状态变更回调
      this.rtc.onstatus("networkstatus", (states) => {
        switch (states) {
          case "connected":
            this.getremotestream();
            this.loading = false;
            this.icestatus = "connected";
            this.content = `连接完成`;
            var timestampNow = new Date().getTime() / 1000;
            var remainTime = timestampNow - this.data.CreateTime;
            this.isexplain = false;
            if (remainTime > 480) {
              this.isexplain = false;
              this.content = `连接完成`;
            } else {
              this.isexplain = true;
              this.content = `云手机app环境准备中`;
              this.timmer = setInterval(() => {
                this.isexplain = false;
                this.content = `连接完成`;
                clearInterval(this.timmer);
              }, 480000 - remainTime * 1000);
            }
            break;
          case "new":
            this.isexplain = false;
            this.content = `网络连接中`;
            this.icestatus = "disconnected";
            this.loadingtext = "网络连接中";
            break;
          case "checking":
            this.isexplain = false;
            this.content = `网络连接中`;
            this.icestatus = "disconnected";
            this.loadingtext = "网络连接中";
            break;
          case "failed":
            this.isexplain = false;
            this.content = `网络连接中`;
            this.icestatus = "disconnected";
            this.loadingtext = "网络连接中";
            this.loading = true;
            this.restart();
            break;
          case "disconnected":
            this.isexplain = false;
            this.icestatus = "disconnected";
            this.content = `重连中...`;
            this.loading = true;
            this.loadingtext = "网络连接断开";
            this.restart();
            break;
        }
      });
      this.rtc.onstatus("channelstatus", (states) => {
        switch (states) {
          case "open":
            this.startunmannedlive();
            break;
          case "close":
            break;
        }
      });
    },
    // Step 3 获取远程媒体流
    getremotestream() {
      let _this = this;
      var remotestream = this.rtc.getremotestream();
      //console.log(remotestream);
      this.$refs.revideo.srcObject = remotestream; // 添加视频流到标签
      var dialog = document.querySelector(".my-info-dialog .el-dialog");
      this.$refs.revideo.onloadedmetadata = function onloadedmetadata() {
        //console.log(this.videoHeight, this.videoWidth);
        if (this.videoHeight / this.videoWidth !== 1) {
          let ratio = this.videoHeight / this.videoWidth;
          _this.ratio = ratio;
          _this.height = _this.zoomwidth * _this.ratio;
          _this.$refs.cvideo.style.height = _this.height + "px";
          _this.$refs.modelbody.style.height = _this.height + "px";
          if (_this.rotate == "portrait") {
            dialog.style.height = _this.height + 35 + "px";
          } else {
            dialog.style.height = _this.zoomwidth + 70 + "px";
          }
        }
      };
    },
    //信息交互(发送消息到服务器)
    sendmsg(message) {
      this.rtc.sendMessage(message);
      if (this.$parent.iftongbu) {
        // this.$bus.$emit("sendgruopmsg", message);
        sendmessage(message, this.list);
      }
    },
    //鼠标落下
    mousedown(e) {
      let message = {
        name: "",
        offsetWidth: this.zoomwidth,
        offsetHeight: parseInt(this.height),
        button: e.button,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        timeStamp: e.timeStamp,
      };
      if (e.button == 0) {
        this.mdown = true;
        message.name = "touchstart";
      } else if (e.button == 1) {
        message.name = "wheeldown";
        message.wheelDelta = "0x01000073";
      } else if (e.button == 2) {
        message.name = "touchrightstart";
        message.wheelDelta = "0x01000075";
      }

      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //鼠标抬起
    mouseup(e) {
      // console.log(e)
      let message = {
        name: "",
        offsetWidth: this.zoomwidth,
        offsetHeight: parseInt(this.height),
        button: e.button,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        timeStamp: e.timeStamp,
      };
      if (e.button == 0) {
        if (this.mdown) {
          this.mdown = false;
          message.name = "touchend";
          if (this.rtc) {
            this.sendmsg(message);
          }
        }
      } else if (e.button == 1) {
        message.name = "wheelup";
        message.wheelDelta = "0x01000073";
        if (this.rtc) {
          this.sendmsg(message);
        }
      } else if (e.button == 2) {
        message.name = "touchrightend";
        message.wheelDelta = "0x01000075";
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    //鼠标移出
    mouseout(e) {
      // console.log(e.type);
      // console.log(e);
      if (this.mdown) {
        this.mdown = false;
        let message = {
          name: "touchend",
          offsetWidth: this.zoomwidth,
          offsetHeight: parseInt(this.height),
          button: e.button,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
          timeStamp: e.timeStamp,
        };
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    //鼠标移动
    mousemove(e) {
      // console.log(e.type);
      if (this.mdown) {
        let message = {
          name: "touchmove",
          offsetWidth: this.zoomwidth,
          offsetHeight: parseInt(this.height),
          button: e.button,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
          timeStamp: e.timeStamp,
        };
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    mousewheel(e) {
      e.preventDefault();
      let msg = {
        name: "mousewheel",
        wheelDelta: "",
        timeStamp: e.timeStamp,
      };
      if (e.wheelDelta < 0) {
        msg.wheelDelta = -1; // 滚轮向上
      } else if (e.wheelDelta > 0) {
        msg.wheelDelta = 1; // 滚轮向下
      }
      if (this.rtc) {
        this.sendmsg(msg);
      }
      //console.log(e);
    },
    blur() {
      console.log("失去焦点");
    },
    //键盘按下
    onkeydown(e) {
      // console.log(e);
      var message = {
        name: "keydown",
        keyCode: transKeyCode(e.keyCode),
        timeStamp: e.timeStamp,
      };
      //CRTL+V Pressed
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 86) {
        message.name = "clipboard";
        //console.log(message);
      }
      if (e.keyCode == 91 || e.keyCode == 18 || e.keyCode == 9) {
        e.preventDefault();
        return;
      }
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //键盘抬起
    onkeyup(e) {
      let message = {
        name: "keyup",
        keyCode: transKeyCode(e.keyCode),
        timeStamp: e.timeStamp,
      };
      if (e.keyCode == 91 || e.keyCode == 18 || e.keyCode == 9) {
        e.preventDefault();
        return;
      }
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //重启
    mainrestart() {
      // this.rtc.closeConnection();
      // this.icestatus = "disconnected";
      // this.content = "正在重启...";

      let phoneIds = [];
      phoneIds.push(this.data.Id);
      this.$store
        .dispatch("group/phonereboot", { PhoneIds: phoneIds })
        .then((res) => {
          //console.log("controlmodel restart response: " + JSON.stringify(res));
          if (res.RetCode == 0) {
            this.$message.success("正在重启");
            this.checkStatusAndRestart("正在重启...");
          } else {
            this.$message.error("重启失败！");
          }
        });
    },
    newphone() {
      let phoneIds = [];
      phoneIds.push(this.data.Id);
      this.$store
        .dispatch("control/phonerenew", { PhoneIds: phoneIds })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("正在改机中");
            this.checkStatusAndRestart("已启动改机,正在重启...");
          } else {
            this.$message.error("改机失败！");
          }
        });
    },
    changephone() {
      let phoneIds = [];
      phoneIds.push(this.data.Id);
      this.$store
        .dispatch("control/phonereset", { PhoneIds: phoneIds })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("正在换机中");
            this.checkStatusAndRestart("已启动换机,正在重启...");
          } else {
            this.$message.error("换机失败！");
          }
        });
    },
    checkStatusAndRestart(msg) {
      this.rtc.closeConnection();
      this.icestatus = "disconnected";
      this.isexplain = false;
      this.content = msg;
      this.timmer = setInterval(() => {
        if (this.icestatus == "connected") {
          clearInterval(this.timmer);
        } else {
          this.restart();
        }
      }, 30000);
    },
    addvolume() {
      let message = {
        name: "addvolume",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    devolume() {
      let message = {
        name: "devolume",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    handlercommand(e) {
      if (e == "forcetk") {
        this.forcetk();
      }
    },
    startapp() {
      let message = {
        name: "startgame",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    forcetk() {
      let message = {
        name: "forcetk",
        content:
          "#!/bin/bash\nam force-stop com.zhiliaoapp.musically\nrm -rf  /data/data/com.zhiliaoapp.musically/files/keva/repo/*\nam start com.zhiliaoapp.musically",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //获取重启进程
    getUPhoneJob(jobId) {
      if (this.timmer !== null) {
        clearInterval(this.timmer);
      }
      var payload = {
        Action: "DescribeUPhoneJob",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        JobIds: [jobId],
        ProductType: this.$route.query.product_type
          ? this.$route.query.product_type
          : "uphone-server",
      };
      this.timmer = setInterval(() => {
        this.$request
          .post("?Action=DescribeUPhoneJob", {
            ...payload,
          })
          .then((res) => {
            if (res.Jobs[0].State == "SUCCESS" && this.timmer) {
              console.log("重启完毕");
              clearInterval(this.timmer);
              this.restart();
            }
            if (res.Jobs[0].State == "FAILED") {
              clearInterval(this.timmer);
              this.$message.error("重启失败");
            }
            if (res.Jobs[0].State == "PARTIAL_SUCCESS") {
              clearInterval(this.timmer);
              this.$message.error("重启失败");
            }
          });
      }, 5000);
    },
    //重新连接
    restart() {
      if (this.rtc != null) {
        //console.log("窗口重连");
        this.rtc.reStart();
      }
    },
    //获取画面延迟回调
    getcurrentround() {
      this.rtc.getNetDelay((roundtime) => {
        this.currentRoundTripTime = roundtime;
      });
    },
    backhome() {
      return new Promise((resolve, reject) => {
        let message = {
          name: "backhome",
        };
        this.sendmsg(message);
        resolve();
      });
    },
    phonemenu() {
      this.backhome().then(() => {
        setTimeout(() => {
          let message = {
            name: "phonemenu",
          };
          this.sendmsg(message);
        }, 500);
      });
    },
    backpage() {
      let message = {
        name: "backlast",
      };
      this.sendmsg(message);
    },
    uploadimage() {
      this.$emit("singleuploadmodel");
    },

    closemodel(done) {
      if (this.timmer !== null) {
        clearInterval(this.timmer);
      }
      //console.log("关闭窗口");
      this.loading = true;
      this.rotate = "portrait";
      this.isexplain = false;
      this.content = "正在建立连接...";
      this.loadingtext = "加载中";
      this.isresult = false;
      this.isduplicate = false;
      this.usecamera = false;
      this.usemicrophone = false;
      this.rtc.Hangup();
      this.rtc = null;
      this.count = 0;
      this.icestatus = "";
      var dialog = document.querySelector(".my-info-dialog .el-dialog");
      var li = document.getElementsByTagName("li");
      this.$refs.modelbody.style.transform = `translate3d(-0px, 0px, 0px) rotate(0deg)`;
      Array.from(li).forEach((item) => {
        item.style.transform = `translate3d(0px, 0px, 0px) rotate(0deg)`;
      });
      dialog.style.width = this.zoomwidth + 35 + "px";
      dialog.style.height = this.height + 35 + "px";
      this.$refs.cvideo.style.height = this.height + "px";
      this.$refs.modelbody.style.height = this.height + "px";
      this.$emit("closemodelcontrol");
    },
    changerotate() {
      if (this.rotate == "portrait") {
        // console.log(this.rotate);
        var dialog = document.querySelector(".my-info-dialog .el-dialog");
        var modelbody = document.querySelector(".modelbody");
        var li = document.querySelectorAll(".setting-ul li");
        if (this.ratio == 1.7777777777777777) {
          this.rotate = "landscape";
          if (this.zoomwidth == 400) {
            this.$refs.modelbody.style.transform = `translate3d(138px, -138px, 0px) rotate(-90deg)`;
          } else {
            this.$refs.modelbody.style.transform = `translate3d(99px, -99px, 0px) rotate(-90deg)`;
          }
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(90deg)`;
          });
          dialog.style.width = this.height + "px";
          dialog.style.height = this.zoomwidth + 70 + "px";
        }
        // ----- 以下分辨率------
        if (this.ratio == 1.9111111111111112) {
          this.rotate = "landscape";
          this.$refs.modelbody.style.transform = `translate3d(123px, -116px, 0px) rotate(-90deg)`;
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(90deg)`;
          });
          dialog.style.width = 620 + "px";
          dialog.style.height = 398 + "px";
        }
        if (this.ratio == 2) {
          this.rotate = "landscape";
          this.$refs.modelbody.style.transform = `translate3d(132px, -132px, 0px) rotate(-90deg)`;
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(90deg)`;
          });
          dialog.style.width = 600 + "px";
          dialog.style.height = 379 + "px";
        }
        return;
      }
      if (this.rotate == "landscape") {
        // console.log(this.rotate);
        var dialog = document.querySelector(".my-info-dialog .el-dialog");
        var li = document.querySelectorAll(".setting-ul li");
        if (this.ratio == 1.7777777777777777) {
          this.rotate = "portrait";
          this.$refs.modelbody.style.transform = `translate3d(-0px, 0px, 0px) rotate(0deg)`;
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(0deg)`;
          });
          // console.log(dialog);
          dialog.style.width = this.zoomwidth + 35 + "px";
          dialog.style.height = this.height + 35 + "px";
        }
        if (this.ratio == 1.9111111111111112) {
          this.rotate = "portrait";
          this.$refs.modelbody.style.transform = `translate3d(-0px, 0px, 0px) rotate(0deg)`;
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(0deg)`;
          });
          dialog.style.width = 375 + "px";
          dialog.style.height = 627 + "px";
        }
        if (this.ratio == 2) {
          this.rotate = "portrait";
          this.$refs.modelbody.style.transform = `translate3d(-0px, 0px, 0px) rotate(0deg)`;
          Array.from(li).forEach((item) => {
            item.style.transform = `translate3d(0px, 0px, 0px) rotate(0deg)`;
          });
          dialog.style.width = 335 + "px";
          dialog.style.height = 644 + "px";
        }
        return;
      }
    },
    closegpsmodel() {
      this.showsetgps = !this.showsetgps;
      this.checkeditem = [];
      var _this = this;
      document.addEventListener("keydown", _this.onkeydown);
      document.addEventListener("keyup", _this.onkeyup);
    },
    showgpsmodel() {
      this.showsetgps = !this.showsetgps;
      var _this = this;
      document.removeEventListener("keydown", _this.onkeydown);
      document.removeEventListener("keyup", _this.onkeyup);
      this.checkeditem.push(this.data.Id);
    },
    forceconnect() {
      if (this.rtc) {
        this.rtc.forceconnent();
      }
      //console.log("发送offer");
    },
    startunmannedlive() {
      if (this.$store.getters.unmannedlivelist) {
        // rtcitem.rtc.stopunmanedlive()
        this.$store.getters.unmannedlivelist.forEach((item, index) => {
          if (item.Id == this.data.Id) {
            this.rtc.startunmanedlive(item.fileurl);
          }
        });
      }
      // let phonelist = this.$store.getters.unmannedlivelist.map((item) => {
      //   return item.phoneId;
      // });
      // if (!phonelist.includes(this.data.phoneId)) {
      //   this.rtc.stopunmanedlive();
      // }
    },
  },
};
</script>
<style scoped lang="scss">
.my-info-dialog {
  ::v-deep .el-dialog__wrapper {
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
  ::v-deep .el-dialog__title {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    color: #409eff;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 0px;
  }
  ::v-deep .el-dialog__header {
    height: 35px;
    line-height: 35px;
    padding: 0;
    box-sizing: border-box;
    padding-left: 10px;
  }
  ::v-deep .el-dialog {
    width: 435px;
    height: 747px;
  }
  ::v-deep .el-dialog__headerbtn {
    top: 12px;
    right: 9px;
  }
  ::v-deep .el-loading-mask {
    background: #fff;
  }
  ::v-deep .el-result {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  ::v-deep .el-result__title p {
    color: #768de2;
  }
  ::v-deep .el-button-style {
    font-size: 12px;
    color: #fff;
    box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
      rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
      rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
    background: linear-gradient(
      135deg,
      rgb(100, 136, 252) 0%,
      rgb(56, 96, 244) 100%
    );
  }
  ::v-deep .el-dropdown {
    position: absolute;
    bottom: 5px;
    width: 35px;
  }
  ::v-deep .el-divider--horizontal {
    margin: 8px 0;
    border-top: 1px #e8eaec;
  }
}
.explain {
  color: black;
}
.camera {
  position: absolute;
  right: 120px;
  padding-top: 2px;
  font-size: 20px;
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(74, 99, 240, 0.93) 0%,
    rgba(42, 130, 228, 1) 100%
  );
  -webkit-background-clip: text;
  cursor: pointer;
  &:hover {
    color: rgb(56, 96, 244);
  }
}
.microphone {
  position: absolute;
  right: 92px;
  top: 10px;
  font-size: 20px;
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(74, 99, 240, 0.93) 0%,
    rgba(42, 130, 228, 1) 100%
  );
  -webkit-background-clip: text;
  cursor: pointer;
  &:hover {
    color: rgb(56, 96, 244);
  }
}
.zoomin {
  position: absolute;
  right: 65px;
  top: 10px;
  font-size: 20px;
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(74, 99, 240, 0.93) 0%,
    rgba(42, 130, 228, 1) 100%
  );
  -webkit-background-clip: text;
  cursor: pointer;
  &:hover {
    color: rgb(56, 96, 244);
  }
}
.zoomout {
  position: absolute;
  right: 36px;
  top: 10px;
  font-size: 20px;
  color: transparent;
  background-image: linear-gradient(
    90deg,
    rgba(74, 99, 240, 0.93) 0%,
    rgba(42, 130, 228, 1) 100%
  );
  -webkit-background-clip: text;
  cursor: pointer;
  &:hover {
    color: rgb(56, 96, 244);
  }
}

.modelbody {
  width: 435px;
  height: 712px;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.c-video {
  width: 400px;
  height: 712px;
  position: relative;
  background: #000;
}
.status {
  font-size: 25px;
  color: #67c23a;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 99;
}
.warning-status {
  font-size: 25px;
  color: #e6a23c;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 99;
}
.setting-area {
  height: 100%;
  flex: 1;
  background: #ecf5ff;
  .setting-ul {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      font-size: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      user-select: none;
      &:hover {
        i,
        span {
          color: rgb(56, 96, 244);
        }
      }
      span {
        font-size: 10px;
        text-align: center;
      }
    }
  }
}
</style>
