var render = function render(){var _vm=this,_c=_vm._self._c;return _c('models',{staticClass:"setipmodel",attrs:{"showmodel":_vm.showsetip,"title":_vm.title,"canceltext":_vm.canceltext,"confirmtext":_vm.confirmtext,"needcancel":_vm.needcancel},on:{"confirm":_vm.confirmipregion,"closemodel":_vm.closeshowipregion,"open":_vm.open}},[_c('div',{staticClass:"warningtitle"},[_c('p',{staticClass:"warningcontent"},[_vm._v("只针对无网版手机配置海外IP")])]),_c('el-form',{ref:"socksform",attrs:{"model":_vm.model}},[_c('com-table',{ref:"comtable",staticStyle:{"width":"100%"},attrs:{"headercellstyle":_vm.headercellstyle,"tablesize":"small","tableheight":"300","columns":_vm.ipcolumns,"data":_vm.model.ipregionlist},on:{"rowClassName":_vm.rowClassName}},[_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"IP地址:IP端口:IP账号:IP密码","width":"400","prop":"IPMsg"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":`ipregionlist[${scope.$index}].IPMsg`,"rules":[
              {
                required: true,
                message: 'IP地址:IP端口:IP账号:IP密码不能为空',
                trigger: 'blur',
              },
              {
                pattern: /^[^\u4e00-\u9fa5]+$/,
                message: '不能存在中文',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{attrs:{"clearable":true,"size":"mini","placeholder":"请输入IP地址:IP端口:IP账号:IP密码"},model:{value:(scope.row.IPMsg),callback:function ($$v) {_vm.$set(scope.row, "IPMsg", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.IPMsg"}})],1)]}}])}),_c('el-table-column',{attrs:{"slot":"action","align":"left","label":"出口IP地址","width":"200","prop":"exportIP"},slot:"action",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"clearable":true,"size":"mini","placeholder":"出口IP地址（选填）"},model:{value:(scope.row.exportIp),callback:function ($$v) {_vm.$set(scope.row, "exportIp", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.exportIp"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }