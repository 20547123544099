const daylist = []
const monthlist = []
const yearlist = []

function getlist(num, type) {
    for (var i = 0; i < num; i++) {
        if (type == "月") {
            const data = {
                value: i + 1,
                label: `${i+1}个${type}`
            }
            monthlist.push(data)
        }
        if (type == "天") {
            const data = {
                value: i + 1,
                label: `${i+1}${type}`
            }
            daylist.push(data)
        }
        if (type == "年") {
            const data = {
                value: i + 1,
                label: `${i+1}${type}`
            }
            yearlist.push(data)
        }
    }
}
getlist(9, "月")
getlist(31, "天")
getlist(5, "年")
const seasonList = [{
    value: '1',
    label: '第一季度'
}, {
    value: '2',
    label: '第二季度'
}, {
    value: '3',
    label: '第三季度'
}, {
    value: '4',
    label: '第四季度'
}]
const specifications = [{
        type: "UPhone Mini",
        describe: "高性价比小游戏挂机",
        cpu: "0.8核｜3.0GHZ",
        yxnc: "2000MB",
        jscc: "10GB",
        fbl: "720x1280",
    },
    {
        type: "UPhone SE",
        describe: "畅享游戏挂机和轻应用",
        cpu: "1核｜3.0GHZ",
        yxnc: "2400MB",
        jscc: "12GB",
        fbl: "720x1280",
    },
    {
        type: "UPhone X",
        describe: "畅玩大多数3D游戏应用",
        cpu: "1.5核｜3.0GHZ",
        yxnc: "3000MB",
        jscc: "15GB",
        fbl: "720x1280",
    },
    {
        type: "UPhone Pro",
        describe: "畅玩超大型3D游戏和元宇宙重度渲染应用",
        cpu: "3核｜3.0GHZ",
        yxnc: "5000MB",
        jscc: "24GB",
        fbl: "720x1280",
    }
]
const dylist = [{
    align1: [{}]
}]

const lineType = [{
        Id: "path-golden",
        Name: "金牌线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占5M带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000直播&\u3000\u3000\u3000精品养号"
    },
    {
        Id: "path-goldenPlus",
        Name: "金牌高清线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占10M带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000高清直播"
    },
    {
        Id: "path-goldenPro",
        Name: "金牌超清线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占20M带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000超清直播"
    },
    {
        Id: "path-platinum",
        Name: "铂金线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占5M带宽&\u3000\u3000\u3000本地住宅IP（ISP）&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000极致用途"
    },
    {
        Id: "path-platinumPlus",
        Name: "铂金高清线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占10M带宽&\u3000\u3000\u3000本地住宅IP（ISP）&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000极致用途"
    },
    {
        Id: "path-platinumPro",
        Name: "铂金超清线路",
        Target: "专为海外直播而生",
        Description: "特点\u3000全球专线直连&\u3000\u3000\u3000独占纯净IP&\u3000\u3000\u3000独占20M带宽&\u3000\u3000\u3000本地住宅IP（ISP）&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000极致用途"
    },
    {
        Id: "path-zircon",
        Name: "视频线路",
        Target: "专为视频养号而生",
        Description: "特点\u3000基于跨境专线&\u3000\u3000\u3000本地住宅IP（ISP）&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000养号&\u3000\u3000\u3000视频带货"
    },
    {
        Id: "path-zirconPremium",
        Name: "精品视频线路",
        Target: "专为视频养号而生",
        Description: "特点\u3000基于跨境专线&\u3000\u3000\u3000独占IP&\u3000\u3000\u3000本地住宅IP（ISP）&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000精品养号&\u3000\u3000\u3000精品视频带货"
    },
    {
        Id: "path-silvery",
        Name: "银牌线路",
        Target: "专为直播养号而生",
        Description: "特点\u3000基于跨境专线&\u3000\u3000\u3000独占IP&\u3000\u3000\u3000独占5M带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000养号&\u3000\u3000\u3000直播辅助管理"
    }
]

const phoneType = [{
        // Id: "phone-advanced",
        // Name: "精品版",
        Id: "universal",
        Name: "通用版",
        Target: "多平台精品养号",
        Description: "特点\u3000\u3000单机独占纯净IP&\u3000\u3000\u3000\u30004M专线带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000TikTok精品短视频&\u3000\u3000\u3000\u3000Facebook&\u3000\u3000\u3000\u3000WhatsApp"

    },
    {
        Id: "phone-shared",
        Name: "共享版",
        Target: "批量养号",
        Description: "特点\u3000\u3000每3部云机共用IP&\u3000\u3000\u3000\u3000与其他账户共享IP&\u3000\u3000\u3000\u3000每部3M专线带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000TikTok短视频&\u3000\u3000\u3000\u3000Facebook&\u3000\u3000\u3000\u3000WhatsApp&\u3000\u3000\u3000\u3000游戏挂机"
    }, {
        Id: "phone-exclusive",
        Name: "独享版",
        Target: "独享",
        Description: "特点\u3000\u3000每3部云机共用纯净IP&\u3000\u3000\u3000\u30003部起售，账户独占IP&\u3000\u3000\u3000\u3000每部3M专线带宽&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000TikTok短视频&\u3000\u3000\u3000\u3000Facebook&\u3000\u3000\u3000\u3000WhatsApp&\u3000\u3000\u3000\u3000游戏挂机"
    },
    {
        Id: "phone-noip",
        Name: "无网版",
        Target: "无网",
        Description: "特点\u3000\u3000自动配置时区、语言、&\u3000\u3000\u3000\u3000gps等信息 无海外ip&\u3000\u3000\u3000\u3000需自行购买配置&\u3000\u3000\u3000\u3000支持多手机配置同一IP&\u3000\u3000\u3000\u3000配置IP前不要开启APP&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000短视频APP&\u3000\u3000\u3000\u3000社媒APP&\u3000\u3000\u3000\u3000不适用直播&"
    },
    {
        Id: "phone-social",
        Name: "天晴定制版",
        Target: "天晴",
        Description: "特点\u3000\u3000自动配置时区、语言、&\u3000\u3000\u3000\u3000gps等信息&\u3000\u3000\u3000\u3000专供海外社交类app养&\u3000\u3000\u3000\u3000号&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000社交类APP&"
    },{
        Id: "phone-matrix",
        Name: "矩阵1:4版",
        Target: "矩阵",
        Description: "特点\u3000\u3000一台开机+三台关机&\u3000\u3000\u3000\u3000自动配置环境信息&\u3000\u3000\u3000\u3000IP需自行购买配置&\u3000\u3000\u3000\u3000支持多手机配置同一IP&\u3000\u3000\u3000\u3000配置IP前不要开启APP&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000短视频APP&\u3000\u3000\u3000\u3000社媒APP&\u3000\u3000\u3000\u3000电商等应用的批量养号&"
    },
    {
        Id: "phone-ip",
        Name: "精品IP版",
        Target: "精品",
        Description: "特点\u3000\u3000独享双ISP静态住宅IP、&\u3000\u3000\u3000\u3000自动配置时区、语言、&\u3000\u3000\u3000\u3000gps等信息&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000社交类APP&\u3000\u3000\u3000\u3000批量养号、短视频运营&\u3000\u3000\u3000\u3000包括TikTok、&\u3000\u3000\u3000\u3000Facebook、Amazon等&"
    },{
        Id: "phone-matrix8",
        Name: "矩阵1:8版",
        Target: "矩阵",
        Description: "特点\u3000\u3000一台开机+七台关机&\u3000\u3000\u3000\u3000自动配置环境信息&\u3000\u3000\u3000\u3000IP需自行购买配置&\u3000\u3000\u3000\u3000支持多手机配置同一IP&\u3000\u3000\u3000\u3000配置IP前不要开启APP&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000短视频APP&\u3000\u3000\u3000\u3000社媒APP&\u3000\u3000\u3000\u3000电商等应用的批量养号&"
    },{
        Id: "phone-matrix16",
        Name: "矩阵1:16版",
        Target: "矩阵",
        Description: "特点\u3000\u3000一台开机+十五台关机&\u3000\u3000\u3000\u3000自动配置环境信息&\u3000\u3000\u3000\u3000IP需自行购买配置&\u3000\u3000\u3000\u3000支持多手机配置同一IP&\u3000\u3000\u3000\u3000配置IP前不要开启APP&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000\u3000短视频APP&\u3000\u3000\u3000\u3000社媒APP&\u3000\u3000\u3000\u3000电商等应用的批量养号&"
    }
    
    /*,{
        Id: "live2",
        Name: "社媒版",
        Target: "精品直播、带货",
        Description: "特点\u3000单机独占IP&\u3000\u3000\u30002M专线带宽&\u3000\u3000\u3000不能安装TikTok&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000Facebook&\u3000\u3000\u3000Whatsapp"
    },{
        Id: "live3",
        Name: "离线版",
        Target: "精品直播、带货",
        Description: "特点\u3000默认配置第三方海外IP&\u3000\u3000\u3000N台机只能同时开机1个&\u3000\u3000\u3000自定义配置离线比N&\u3000\u3000\u3000N台机使用同一个IP&\u3000\u3000\u30001<=N<=20&\u3000\u3000\u3000APP无限制&\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000\u3000适用\u3000大规模场景"
    }*/
]

const phonestatus = [{
        status: 'UNDEFINED_STATE',
        statusname: '未知'
    },
    {
        status: 'STARTING',
        statusname: '开机中'
    },
    {
        status: 'START_FAILED',
        statusname: '开机失败'
    },
    {
        status: 'STOPPING',
        statusname: '关机中'
    },
    {
        status: 'STOP_FAILED',
        statusname: '关机失败'
    },
    {
        status: 'STOPPED',
        statusname: '已关机'
    },
    {
        status: 'RESETTING',
        statusname: '重置中'
    },
    {
        status: 'RESET_FAILED',
        statusname: '重置失败'
    },
    {
        status: 'SETTING_ROOT',
        statusname: '设置Boot中'
    },
    {
        status: 'SET_ROOT_FAILED',
        statusname: '设置Boot失败'
    },
    {
        status: 'SETTING_GPS',
        statusname: '设置GPS中'
    },
    {
        status: 'SET_GPS_FAILED',
        statusname: '设置GPS失败'
    },
    {
        status: 'SETTING_CONFIG',
        statusname: '设置分辨率中'
    },
    {
        status: 'SET_CONFIG_FAILED',
        statusname: '设置分辨率失败'
    },
    {
        status: 'SETTING_SOCKS',
        statusname: '配置IP中'
    },
    {
        status: 'SET_SOCKS_FAILED',
        statusname: '配置IP失败'
    },
    {
        status: 'RENEWING',
        statusname: '更改机型中'
    },
    {
        status: 'RENEW_FAILED',
        statusname: '更改机型失败'
    },
    {
        status: 'CREATE_FAILED',
        statusname: '创建失败'
    },
    {
        status: 'DELETING',
        statusname: '删除中'
    },
    {
        status: 'DELETE_FAILED',
        statusname: '删除失败'
    },
    {
        status: 'RUNNING',
        statusname: '运行中'
    },
    {
        status: 'CREATING',
        statusname: '创建中'
    },
    {
        status: 'REBOOTING',
        statusname: '重启中'
    },
    {
        status: 'REBOOT_FAILED',
        statusname: '重启失败'
    },
    {
        status: 'DUE',
        statusname: '已过期'
    },
    {
        status: 'RUNNING',
        statusname: '运行中'
    },
]
var productions = [
    {
      ProductId: "universal",
      Name: "通用版",
    },
    {
      ProductId: "matrix",
      Name: "矩阵版",
    },
    {
      ProductId: "live",
      Name: "直播版",
    },
    {
      ProductId: "phone-noip",
      Name: "无网版",
    },
    {
        ProductId: "phone-social",
        Name: "天晴定制版",
      },
    {
      ProductId: "phone-advanced",
      Name: "精品版",
    },
    {
      ProductId: "phone-shared",
      Name: "共享版",
    },
    {
      ProductId: "phone-exclusive",
      Name: "独享版",
    },
    {
        ProductId: "phone-matrix",
        Name: "矩阵1:4版",
    },
    {
        ProductId: "phone-matrix8",
        Name: "矩阵1:8版",
      }, {
        ProductId: "phone-matrix16",
        Name: "矩阵1:16版",
      },
  ];
export {
    daylist,
    seasonList,
    monthlist,
    yearlist,
    specifications,
    lineType,
    phoneType,
    phonestatus,
    productions
}
