import ucloudrtcController from "../sdk/groupcontroller";
import store from "../store";

function updatelist(phoneList, timeout, isshowlist) {
    console.log(phoneList)
    console.log(store.state.group.phoneList)
    let oldphonelist = store.getters.phoneList
    console.log("old list length: ");
    console.log(oldphonelist)
    let newphonelist = phoneList
    console.log("new list length: ");
    console.log(newphonelist)
    if (oldphonelist.length == newphonelist.length) {
        //console.log("only update.");
        connection(newphonelist, timeout, isshowlist);
        return;
    }
    let relist = []
    let inlist = []
    for (let i = 0; i < oldphonelist.length; i++) {
        let flag = false
        for (let j = 0; j < newphonelist.length; j++) {
            if (oldphonelist[i].phoneId == newphonelist[j].phoneId) {
                flag = true
                break
            }
        }
        if (!flag) {
            relist.push(oldphonelist[i]) //需要断开的云机
        }

    }
    for (let i = 0; i < newphonelist.length; i++) {
        let flag = false
        for (let j = 0; j < oldphonelist.length; j++) {
            if (newphonelist[i].phoneId == oldphonelist[j].phoneId) {
                flag = true
                break
            }
        }
        if (!flag) {
            inlist.push(newphonelist[i]) //需要连接的云机
        }
    }
    console.log("需要连接的")
    console.log(inlist)
    console.log("需要断开的")
    console.log(relist)
    if (!relist.length) {
        store.commit({
            type: 'group/setPhoneList',
            phoneList: phoneList
        })
        //console.log("加")
        connection(inlist, timeout, isshowlist);
    } else {
        store.commit({
            type: 'group/setPhoneList',
            phoneList: phoneList
        })
        //console.log("减")
        disconnection(relist)
        if (isshowlist.length != 0) {
            connection(newphonelist, timeout, isshowlist);
        }
    }
}

function connection(list, timeout, isshowlist) {
    let needconnect = false;
    let timer = 0
    list.forEach((item, index) => {
        let showflag = "ntos";
        let sendkind = 0; //1:start update 2:stop update 3:keep up
        for (let i = 0; i < isshowlist.length; i++) {
            if (Object.keys(isshowlist[i]) == item.phoneId) {
                showflag = Object.values(isshowlist[i]);
                //console.log(Object.keys(isshowlist[i]) + " = " + Object.values(isshowlist[i]));
            }
        }
        if (showflag == "ntos") {
            sendkind = 1;
            needconnect = true;
        } else if (showflag == "htos") {
            sendkind = 1;
        } else if (showflag == "ntoh") {
            sendkind = 3;
            needconnect = true;
        } else if (showflag == "stoh") {
            sendkind = 2;
        } else if (showflag == "stos") {
            sendkind = 3;
        } else if (showflag == "htoh") {
            sendkind = 3;
        }
        let loadingParams = {
            Id: item.phoneId,
            mediaConstraints: {
                audio: false,
                video: true,
                usecamera: false,
                usemicrophone: false,
            },
            debug: true
        };

        if (needconnect) {
            if (showflag == "ntoh") {
                timeout += 300;
                //console.log("rtc connect plus 300ms.");
            }
            setTimeout(() => {
                creatertcserve(loadingParams, item.phoneId, timeout, sendkind);
            }, timer);
            // timer = timer + 1000
            //console.log("connection->creatertcserve: " + item.phoneId + " " + sendkind);
        } else {
            let rtc = getrtc(item.phoneId);
            if (rtc) {
                rtc.sendupdatenotice(sendkind);
            }
        }
    })
}

function disconnection(list) {
    try {
        list.forEach((fitem, findex) => {
            store.getters.rtclist.forEach((sitem, sindex) => {
                if (fitem.phoneId == sitem.phoneId) {
                    sitem.rtc.Hangup();
                    sitem.icestatus = "disconnected";
                    store.commit("group/clearRtc", sindex);
                    console.log(store.getters.rtclist);
                }
            })
        })
    } catch (error) {
        return
    }
}

function creatertcserve(loadingParams, phoneId, timeout, kind = 0) {
    let rtcitem = {
        rtc: null,
        phoneId: ''
    }
    rtcitem.phoneId = phoneId
    rtcitem.rtc = new ucloudrtcController(loadingParams);
    rtcitem.rtc.sendupdatenotice(kind);
    store.commit("group/setRtcList", rtcitem)
    //console.log("creatertcserve setRtcList.");
    setTimeout(() => {
        startconnection(rtcitem)
    }, timeout);
}

function startconnection(rtcitem) {
    var count = 0
    var countfir = 0
    var countsec = 0
    rtcitem.rtc.startConnection()
    rtcitem.rtc.onstatus("devicestatus", (states) => {
        if (states == 136) {
            rtcitem.rtc.closeConnection()
            rtcitem.icestatus = states
        }
        if (states == 500) {
            rtcitem.icestatus = states
            rtcitem.isresult = false
            rtcitem.rtc.reStart()
        }
        if (states == 1000 || states == 1001 || states == 1002 || states == 1003 || states == 1004 || states == 1005 || states == 1006 || states == 1007 || states == 1008 || states == 1009 || states == 1026 || states == 1027 || states == 1028 || states == 1031 || states == 73002 || states == 90012 || states == 1038) {
            if (rtcitem.icestatus == "connected") {
                console.log("------连接成功 ----")
            } else {
                switch (states) {
                    case 1003:
                        ++countfir
                        rtcitem.icestatus = states
                        if (countfir == 4) {
                            rtcitem.icestatustext = `设备已关机${1003}`
                        }
                        break;
                    case 1008:
                        rtcitem.icestatus = states
                        rtcitem.icestatustext = `服务器未响应${1008}`
                        break;
                    case 1026:
                        ++count;
                        rtcitem.icestatus = states
                        rtcitem.isresult = false
                        if (count == 5) {
                            // rtcitem.isresult = true
                            // rtcitem.icestatustext = "设备被占用"
                            rtcitem.rtc.forceconnent();
                            rtcitem.rtc.setCount()
                            return
                        }
                        break;
                    case 1038:
                        rtcitem.icestatus = states
                        break;
                    case 73002:
                        ++countsec
                        rtcitem.icestatus = states
                        if (countsec == 4) {
                            rtcitem.icestatustext = `设备未启动${73002}`
                        }
                        break;
                    case 42100:
                        rtcitem.icestatus = states
                        rtcitem.icestatustext = `请求参数错误{42100}`
                        break;
                    default:
                        rtcitem.icestatus = states
                        rtcitem.icestatustext = `连接错误${states}`
                        break;
                }
                rtcitem.rtc.reStart()
            }
        }
    });
    //p2p连接状态变更回调
    rtcitem.rtc.onstatus("networkstatus", (states) => {
        //console.log(states)
        rtcitem.icestatus = states
        rtcitem.icestatustext = ""
        rtcitem.isresult = false
        if (states == "connected") {
            let stream = rtcitem.rtc.getremotestream()
            rtcitem.stream = stream
        }
        if (states == "new") {
            rtcitem.icestatustext = "连接中"
            // rtcitem.icestatustext = "new"
        }
        if (states == "checking") {
            rtcitem.icestatustext = "连接中"
            // rtcitem.icestatustext = "checking"
        }
        if (states == "failed") {
            rtcitem.icestatustext = "网络存在波动"
            // rtcitem.icestatustext = "failed"
            //console.log("连接失败" + rtcitem.phoneId)
            rtcitem.rtc.reStart()
        }
        if (states == "disconnected") {
            // rtcitem.icestatustext = "disconnected"
            rtcitem.icestatustext = "连接断开"
            console.log("disconnected连接断开" + rtcitem.phoneId)
            rtcitem.rtc.reStart()
        }
    });
    //datachannel
    rtcitem.rtc.onstatus("channelstatus", (states) => {
        switch (states) {
            case "open":
                if (store.getters.liveList) {
                    // rtcitem.rtc.stopunmanedlive()
                    store.getters.liveList.forEach((item, index) => {
                        if (item.phoneId == rtcitem.phoneId) {
                            rtcitem.rtc.startunmanedlive(store.getters.rtmpurl)
                        }
                    })
                }
                if (store.getters.unmannedlivelist) {
                    // rtcitem.rtc.stopunmanedlive()
                    store.getters.unmannedlivelist.forEach((item, index) => {
                        if (item.phoneId == rtcitem.phoneId) {
                            rtcitem.rtc.startunmanedlive(item.fileurl)
                        }
                    })
                }
                // let phonelist = store.getters.unmannedlivelist.map((item) => {
                //     return item.phoneId
                // })
                // if (!phonelist.includes(rtcitem.phoneId)) {
                //     rtcitem.rtc.stopunmanedlive()
                // }

                break;
            case "close":
                console.log("close");
                break;
        }
        console.log(states)
    })
}

function getstream(phoneId) {
    let stream
    store.getters.rtclist.forEach((item, index) => {
        if (item.phoneId == phoneId) {
            stream = item.stream
        }
    })
    return stream
}

function getrtc(phoneId) {
    let rtc = null;
    store.getters.rtclist.forEach((item, index) => {
        if (item.phoneId == phoneId) {
            rtc = item.rtc;
        }
    })
    return rtc;
}

function sendmessage(message, phonelist) {
    phonelist.forEach((pitem, pindex) => {
        store.getters.rtclist.forEach((item, index) => {
            if (item.rtc && pitem.phoneId == item.phoneId && pitem.itemchecked == true) {
                item.rtc.sendMessage(message)
            }
        })
    })
}

function groupconnection(phoneList, timeout = 0, isshowlist = []) {
    updatelist(phoneList, timeout, isshowlist);
}

export {
    groupconnection,
    sendmessage,
    getstream,
    getrtc
}