<template>
  <models
    :showmodel="showmatrixrenew"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closematrixrenew"
    class="ifeditename"
  >
    <div class="warningtitle">
      <p class="warningcontent">
       Tips：矩阵云手机续费时，需要同时续费同一订单的其他矩阵云手机
      </p>
    </div>
    <div class="down-tree">
      <el-tree
        :data="data"
        default-expand-all="true"
        style="height: 200px"
      ></el-tree>
      <!-- </el-form> -->
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import ComTable from "../../../components/Table/index.vue";
export default {
  props: {
    showmatrixrenew: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    defaultArr: {
      type: Array,
      default: () => [],
    },
    dataArr: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Object,
    },
  },
  data() {
    return {
      realList: [],

      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },

      alreadychecked: {},

      data: [
        {
          label: "订单组1",
          children: [
            {
              label: "phone1",
            },
            {
              label: "phone2",
            },
            {
              label: "phone3",
            },
            {
              label: "phone4",
            },
            {
              label: "phone4",
            },
            {
              label: "phone5",
            },
          ],
        },
        {
          label: "订单组2",
          children: [
            {
              label: "phone1",
            },
            {
              label: "phone2",
            },
            {
              label: "phone3",
            },
            {
              label: "phone4",
            },
            {
              label: "phone4",
            },
            {
              label: "phone5",
            },
          ],
        },
        {
          label: "订单组3",
          children: [
            {
              label: "phone1",
            },
            {
              label: "phone2",
            },
            {
              label: "phone3",
            },
            {
              label: "phone4",
            },
            {
              label: "phone4",
            },
            {
              label: "phone5",
            },
          ],
        },
      ],
      checkedDetail: [],
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
      showhistroyip: false,
    };
  },
  watch: {
    checkList(val) {
      this.realList = val;
      console.log("fffff", this.realList);
    },
  },
  components: {
    Models,
    ComTable,
  },
  methods: {
    open() {
      //   let obj = {
      //     status: this.alreadychecked.Id,
      //   };
      //   this.ipregionlist.push(obj);
      //   console.log(this.model.ipregionlist);
    },
    // 点击确定
    confirmedit() {
      this.$emit("confirmedit", this.realList);
      this.$emit("closematrixrenew");
    },
    closematrixrenew() {
      this.realList = this.checkList;
      this.$emit("closematrixrenew");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 500px;
    height: 550px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding-top: 15px;
    height: 380px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
  .el-cascader-panel {
    height: 300px;
  }
  .down-tree {
    height: 350px;

    display: block;

    overflow-y: scroll;
  }
}
</style>