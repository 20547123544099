<template>
  <div class="phonelist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">矩阵云手机列表</span>
    </div>

    <div class="content">
      <div class="list-top">
        <el-button
          v-if="!subAccount"
          type="primary"
          class="createphone"
          @click="navtocreate"
          >创建矩阵云手机</el-button
        >
        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchChangeGroup"
          @click="changecheckworkgroup"
          >批量工作组切换</el-button
        >
        <el-button type="primary" class="uploadphone" @click="matrixupload"
          >批量上传文件至云手机</el-button
        >
        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchRenew"
          @click="batchrenew"
          >矩阵批量续费</el-button
        >
        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchChangeGroup"
          @click="batchchangegroup"
          >批量修改业务组</el-button
        >

        <el-button
          v-if="!subAccount"
          type="primary"
          class="batchChangeGroup"
          @click="showbatchsetipmodel"
          >批量配置IP</el-button
        >
        <el-button
          size="mini"
          type="primary"
          class="refreshList"
          @click="handleMatrixSearch"
          >刷新</el-button
        >
        <el-button
          class="filter-item"
          size="mini"
          type="primary"
          @click="handleMatrixSearch"
        >
          搜索
        </el-button>
        <el-input
          class="search-input"
          v-model.trim="matrixquery"
          size="mini"
          placeholder="请输入搜索文本（支持模糊搜索）"
          style="width: 220px"
          @keyup.enter.native="handleMatrixSearch"
          clearable
        />
      </div>
      <div class="screen" v-if="isMatrixSearch">
        <div class="screen-title">
          共{{ this.matrixGroups.length }}条数据<el-divider
            direction="vertical"
          ></el-divider
          >筛选条件：{{ this.matrixquery }}
        </div>
        <el-button
          size="mini"
          type="primary"
          class="clear"
          @click="refreshmatrixlist"
          >清空</el-button
        >
      </div>
      <com-table
        v-loading="matrixLoading"
        ref="comtable"
        class="matrixgroupList"
        :headercellstyle="headercellstyle"
        :columns="showMatrixGroupColumn"
        :data="matrixGroups"
        :rowkey="(matrixGroups) => matrixGroups.MatrixGroupId"
        tableheight="calc(100vh - 300px)"
        :isselectcount="true"
        :selectcount="matrixselection.length"
        :pagination="pagination2"
        @handleSelectionChange="handleMatrixSelectionChange"
        @cellmouseenter="cellmouseentermatrix"
        @cellmouseleave="cellmouseleavematrix"
        @sortChange="sortChange"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="260"
        >
          <template slot-scope="scope">
            <el-button
              class="modifybtn"
              type="primary"
              size="mini"
              @click="phoneDetailClick(scope.row)"
              >详情</el-button
            >
            <el-button
              class="modifybtn"
              type="primary"
              size="mini"
              @click="changeworkgroup(scope.row)"
              >工作组切换</el-button
            >
            <el-dropdown v-if="!subAccount" class="more-dropdown">
              <el-button
                icon="el-icon-more"
                class="morebtn"
                size="mini"
                type="primary"
              >
              </el-button>
              <template>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click.native="singleuploadmatrix(scope.row)"
                    >上传</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="showAutoRenewModel(scope.row)"
                    >自动续费管理</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="scope.row.ProductId == 'phone-noip'"
                    @click.native="showsetipmodel(scope.row)"
                    >配置IP</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="gorenew(scope.row)"
                    >续费</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              class="modifybtn"
              v-else-if="scope.row.ProductId == 'phone-noip'"
              @click.native="showsetipmodel(scope.row)"
              type="primary"
              size="mini"
            >
              配置IP
            </el-button>
          </template>
        </el-table-column>
      </com-table>
      <el-drawer
        title="我是标题"
        :visible.sync="drawer2"
        :with-header="false"
        size="80%"
      >
        <br />
        <div class="content">
          <div class="list-top">
            <el-button type="primary" class="uploadphone" @click="upload"
              >上传文件至云手机</el-button
            >

            <el-button
              size="mini"
              type="primary"
              class="refreshList"
              @click="handleSearch"
              >刷新</el-button
            >
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              @click="handleSearch"
            >
              搜索
            </el-button>
            <el-input
              class="search-input"
              v-model.trim="query"
              size="mini"
              placeholder="请输入搜索文本（支持模糊搜索）"
              style="width: 220px"
              @keyup.enter.native="handleSearch"
              clearable
            />
          </div>
          <div class="screen" v-if="isSearch">
            <div class="screen-title">
              共{{ this.tabledata.length }}条数据<el-divider
                direction="vertical"
              ></el-divider
              >筛选条件：{{ this.query }}
            </div>
            <el-button
              size="mini"
              type="primary"
              class="clear"
              @click="refreshlist"
              >清空</el-button
            >
          </div>
          <com-table
            v-loading="loading"
            ref="comtable"
            class="phonelist"
            :headercellstyle="headercellstyle"
            :columns="showTableColumn"
            :data="tabledata"
            :rowkey="(tabledata) => tabledata.Id"
            tableheight="calc(100vh - 300px)"
            :isselectcount="true"
            :selectcount="selection.length"
            :pagination="pagination"
            @handleSelectionChange="handleSelectionChange"
            @cellmouseenter="cellmouseenter"
            @cellmouseleave="cellmouseleave"
            @sortChange="sortChange"
          >
            <el-table-column
              align="center"
              header-align="center"
              slot="action"
              label="操作"
              min-width="260"
            >
              <template slot-scope="scope">
                <el-button
                  class="modifybtn"
                  type="primary"
                  size="mini"
                  v-if="scope.row.State == 'RUNNING'"
                  @click="login(scope.row)"
                  >登录</el-button
                >
                <el-button
                  class="modifybtn"
                  type="primary"
                  size="mini"
                  v-if="scope.row.State == 'RUNNING'"
                  @click="singleupload(scope.row)"
                  >上传</el-button
                >

                <el-button
                  class="modifybtn"
                  @click.native="showsetipmodel(scope.row)"
                  type="primary"
                  size="mini"
                >
                  配置IP
                </el-button>
              </template>
            </el-table-column>
          </com-table>
        </div>
      </el-drawer>
    </div>
    <delete-item
      :title="title"
      canceltext="取消"
      confirmtext="确认"
      :showdelete="showdelete"
      @closedelete="closedelete"
      :row="row"
    />
    <edite-group
      title="修改分组"
      :row="editrow"
      :matrixrow="matrixselectorigin"
      :showeditegroup="showeditegroup"
      @closeeditegroup="closeeditegroup"
    />
    <edite-name
      title="修改名称"
      :row="row"
      :showeditename="showeditename"
      @closeeditename="closeeditename"
    />
    <edite-matrix-name
      title="修改名称"
      :row="row"
      :showeditename="showeditematrixname"
      @closeeditename="closeeditematrixname"
    />
    <single-up-load
      title="上传至云手机"
      :needfooter="false"
      :phoneid="this.row.Id"
      :phonename="this.row.Name"
      :row="uploadrow"
      :showsingleuploadmodel="showsingleuploadmodel"
      @closeshowsingleupload="closeshowsingleupload"
    />

    <control-model
      @closemodelcontrol="closemodelcontrol"
      :showmodel="showmodel"
      :data="modeldata"
      :modeldata="modeldata"
      :iftongbu="iftongbu"
      @singleuploadmodel="singleuploadmodel"
    />
    <renew-order :row="row" :drawer="drawer" @closedrawer="closedrawer" />
    <auto-renew
      title="自动续费管理"
      :row="row"
      :matrixGroupId="currentMatrixGroupId"
      :showAutoRenew="showAutoRenew"
      @closeAutoRenew="closeAutoRenew"
    />
    <set-ip
      title="配置IP"
      :row="row"
      :needfooter="false"
      :showsetip="showsetip"
      @closesetipmodel="closesetipmodel"
    />
    <work-group-change
      title="工作组切换"
      :work-group1="workGroup1"
      :work-group2="workGroup2"
      :work-group3="workGroup3"
      :work-group4="workGroup4"
      :work-group5="workGroup5"
      :work-group6="workGroup6"
      :work-group7="workGroup7"
      :work-group8="workGroup8"
      :work-group9="workGroup9"
      :work-group10="workGroup10"
      :work-group11="workGroup11"
      :work-group12="workGroup12"
      :work-group13="workGroup13"
      :work-group14="workGroup14"
      :work-group15="workGroup15"
      :work-group16="workGroup16"
      :productId="currentProductId"
      :showworkgroupchange="showworkgroupchange"
      :matrixGroupIds="matrixGroupIds"
      :workGroupId="currentWorkGroupId"
      @confirmedit="workgroupchangeclick"
      @closeworkgroupchange="closeworkgroupchange"
    />

    <!-- <matrix-renew
      title="矩阵续费"
      :check-list="checkProp"
      :showmatrixrenew="showmatrixrenew"
      @closematrixrenew="closematrixrenew"
      @confirmedit="matrixrenewclick"
    /> -->

    <batch-set-ip
      title="批量配置IP"
      :row="rowlist"
      :needfooter="false"
      :showbatchsetip="showbatchsetip"
      @closebatchsetipmodel="closebatchsetipmodel"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import RenewOrder from "./RenewOrder/index";
import DeleteItem from "./Models/DeleteItem";
import EditeGroup from "./Models/EditeGroup";
import EditeName from "./Models/EditeName";
import EditeMatrixName from "./Models/EditeMatrixName";
import SettingList from "./Models/SettingList";
import WorkGroupChange from "./Models/WorkGroupChange";
import MatrixRenew from "./Models/MatrixRenew";

import { matrixphoneList, matrixgroupList } from "@/utils/tableList";
import navigation from "@/utils/navigation";
import { mapGetters } from "vuex";
import SingleUpLoad from "../PhoneControl/Models/SingleUpLoad.vue";
import ControlModel from "../PhoneControl/ControlModel/index.vue";
import PhoneListControlModel from "../PhoneControl/PhoneListControlModel/index.vue";
import { groupconnection } from "@/utils/groupconnection";
import AutoRenew from "./Models/AutoRenew";
import SetIp from "./Models/SetIp.vue";
import BatchSetIp from "./Models/BatchSetIp.vue";

import { phonestatus, productions } from "@/utils/optionList";
import { wechatbind } from "@/api/user";

export default {
  name: "matrixgroupList",
  data() {
    return {
      isSearch: false,
      isMatrixSearch: false,
      query: "",
      matrixquery: "",
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      alltabledata: [],
      tabledatabak: [],
      newtabledata: [],
      matrixphoneList,
      matrixgroupList,
      showTableColumn: matrixphoneList,
      showMatrixGroupColumn: matrixgroupList,
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
       pagination2: {
        current: 1,
        size: 20,
        total: 1,
      },
      showdelete: false,
      row: {},
      editrow: [],
      uploadrow: [],
      renewrow: [],
      rowlist: [],
      title: "删除云手机",
      drawer: false,
      showeditegroup: false,
      showeditename: false,
      showeditematrixname: false,
      showAutoRenew: false,
      showworkgroupchange: false,
      showmatrixrenew: false,
      selection: [],
      selection2: [],
      matrixselection: [],
      matrixIdselection: [],
      matrixphoneselection: [],
      matrixtempphoneselection: [],
      phoneRegions: [],
      selectorigin: [],
      matrixselectorigin: [],
      matrixGroups: [],
      remindTime: 7 * 86400,
      needReminder: false,
      remindContent: "",
      remindTimeList: [],
      subAccounts: [],
      currentAccount: "全部",
      showcolumn: false,
      showsingleuploadmodel: false,
      showmodel: false,
      iftongbu: false,
      modeldata: {},
      checkProp: [], // 选中字段
      DefaultPropertyArrForManage: [
        "名称",
        "ID",
        "规格",
        "IP地域",
        "IP",
        "状态",
        "剩余时间",
        "业务组",
      ], // 默认选中字段
      showsetip: false,
      showbatchsetip: false,
      sortProp: "",
      sortOrderDesc: true,
      timer: null,
      matrixLoading: false,
      loading: false,
      workGroup1: [],
      workGroup2: [],
      workGroup3: [],
      workGroup4: [],
      workGroup5: [],
      workGroup6: [],
      workGroup7: [],
      workGroup8: [],
      workGroup9: [],
      workGroup10: [],
      workGroup11: [],
      workGroup12: [],
      workGroup13: [],
      workGroup14: [],
      workGroup15: [],
      workGroup16: [],

      matrixGroupIds: [],
      drawer2: false,
      currentMatrixGroupId: "",
      currentProductId: "",
      currentWorkGroupId: "",
    };
  },
  computed: {
    ...mapGetters(["subAccount"]),
  },
  watch: {
    tabledata: {
      handler: function (val, oldval) {
        if (val !== oldval) this.newtabledata = val;
      },
      deep: true,
    },
  },
  components: {
    ComTable,
    DeleteItem,
    RenewOrder,
    EditeGroup,
    EditeName,
    SingleUpLoad,
    PhoneListControlModel,
    ControlModel,
    AutoRenew,
    SettingList,
    SetIp,
    WorkGroupChange,
    MatrixRenew,
    BatchSetIp,
    EditeMatrixName,
  },
  methods: {
    getrefreshtoken() {
      this.$store.dispatch("user/userresettoken").then((res) => {
        console.log("userresettoken: " + JSON.stringify(res));
      });
    },
    showsetipmodel(row) {
      this.row = row;
      this.showsetip = !this.showsetip;
    },
    closesetipmodel() {
      this.showsetip = !this.showsetip;
    },
    navtocreate() {
      navigation.navigateTo({
        url: "creatematrixphone",
        params: {},
      });
    },
    refreshlist() {
      this.query = "";
      this.getphonelist();
    },
    refreshmatrixlist() {
      this.matrixquery = "";
      this.getmatrixgrouplist();
    },
    showdrawer(row) {
      this.drawer = !this.drawer;
      this.row = row;
    },
    closedrawer() {
      this.drawer = !this.drawer;
    },
    closedelete() {
      this.showdelete = !this.showdelete;
    },
    showdeleteitem(row) {
      this.row = row;
      this.showdelete = !this.showdelete;
    },
    sortChange(prop, order) {
      console.log(prop, order);
      if (!order) {
        (this.sortOrderDesc = true), (this.sortProp = "");
      } else if (prop == "RemainTime") {
        this.sortProp = "ExpireTime";
        this.sortOrderDesc = order == "descending";
      }
    },
    sortMethod(data) {
      let sign = this.sortProp;
      if (sign != "ExpireTime") {
        sign = "CreateTime";
      }
      data = data.sort((a, b) => {
        if (this.sortOrderDesc) {
          return b[sign] - a[sign];
        } else {
          return a[sign] - b[sign];
        }
      });
      return data;
    },

    editegroup(row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改分组功能，请登录主账号进行操作~"
        );
        return;
      }
      Promise.all([this.getMatrixGroupPhoneList([row.MatrixGroupId])]).then(
        ([PhoneInfos]) => {
          this.matrixselectorigin = [row];
          this.editrow = PhoneInfos;
          this.showeditegroup = !this.showeditegroup;
        }
      );
    },
    closeeditegroup() {
      this.showeditegroup = !this.showeditegroup;
      this.handleMatrixSearch();
      this.editrow = [];
      this.$refs.comtable.$refs.myTable.clearSelection();
    },

    batchchangegroup() {
      if (Object.entries(this.matrixselection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }

      Promise.all([this.getMatrixGroupPhoneList(this.matrixselection)]).then(
        ([PhoneInfos]) => {
          this.editrow = PhoneInfos;
          this.showeditegroup = !this.showeditegroup;
        }
      );
    },
    editename(row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改名称功能，请登录主账号进行操作~"
        );
        return;
      }
      this.row = row;
      this.showeditename = !this.showeditename;
    },
    closeeditename() {
      this.showeditename = !this.showeditename;
      this.handleSearch();
    },
    editematrixname(row) {
      if (this.subAccount) {
        this.$message.warning(
          "子账号暂不支持修改名称功能，请登录主账号进行操作~"
        );
        return;
      }
      this.row = row;
      this.showeditematrixname = !this.showeditematrixname;
    },
    closeeditematrixname() {
      this.showeditematrixname = !this.showeditematrixname;
      this.handleMatrixSearch();
    },
    showAutoRenewModel(row) {
      console.log(row);
      this.currentMatrixGroupId = row.MatrixGroupId;

      let data = {
        MatrixGroupId: row.MatrixGroupId,
      };
      this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.row = res.PhoneInfos;
          this.showAutoRenew = !this.showAutoRenew;
        }
      });
    },
    closeAutoRenew() {
      this.showAutoRenew = !this.showAutoRenew;
      this.handleMatrixSearch();
      this.$refs.comtable.$refs.myTable.clearSelection();
    },
    upload() {
      if (Object.entries(this.selection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      this.uploadrow = this.selection;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    matrixupload() {
      if (Object.entries(this.matrixselection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      Promise.all([
        this.getUploadMatrixGroupPhoneList(this.matrixselection),
      ]).then(([PhoneInfos]) => {
        this.uploadrow = PhoneInfos;
        this.showsingleuploadmodel = !this.showsingleuploadmodel;
      });
    },

    singleuploadmatrix(row) {
      Promise.all([
        this.getUploadMatrixGroupPhoneList([row.MatrixGroupId]),
      ]).then(([PhoneInfos]) => {
        this.uploadrow = PhoneInfos;
        this.showsingleuploadmodel = !this.showsingleuploadmodel;
      });
    },

    singleupload(row) {
      this.selectorigin = [row];
      this.selection2 = [];
      for (let item of [row]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection2.push(renewform);
      }
      this.uploadrow = this.selection2;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },
    closeshowsingleupload() {
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
      this.handleSearch();
      this.selection2 = [];
    },
    singleuploadmodel() {
      this.selection2 = [];
      for (let item of [this.modeldata]) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        };
        this.selection2.push(renewform);
      }
      this.uploadrow = this.selection2;
      this.showsingleuploadmodel = !this.showsingleuploadmodel;
    },

    phoneDetailClick(row) {
      this.currentMatrixGroupId = row.MatrixGroupId;
      this.getphonelist();
      this.drawer2 = true;
    },
    login(row) {
      if (row.State == "DUE") {
        this.$message.warning("云手机已过期");
        return;
      } else if (row.State == "RESETTING") {
        this.$message.warning("设备重置中");
        return;
      } else if (row.State == "REBOOTING") {
        this.$message.warning("设备重启中");
        return;
      } else if (row.State == "CREATING") {
        this.$message.warning("设备创建中");
        return;
      } else if (row.State == "RENEWING") {
        this.$message.warning("更换机型中");
        return;
      } else if (row.State == "CREATE_FAILED") {
        this.$message.warning("设备创建失败");
        return;
      } else if (row.State == "SETTING_ROOT") {
        this.$message.warning("设置Boot中");
        return;
      }
      console.log(row);
      this.modeldata = {
        phoneId: row.Id,
        ...row,
      };
      this.showmodel = !this.showmodel;
    },
    closemodelcontrol() {
      this.showmodel = !this.showmodel;
    },
    batchrenew() {
      if (Object.entries(this.matrixselection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }

      Promise.all([
        this.getUploadMatrixGroupPhoneList(this.matrixselection),
      ]).then(([PhoneInfos]) => {
        this.renewrow = PhoneInfos;
        navigation.navigateTo({
          url: "renewconfirm",
          params: {
            payload: {
              ...this.renewrow,
            },
          },
        });
      });
    },
    gorenew(item) {
      let renewform = [
        {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
        },
      ];

      navigation.navigateTo({
        url: "renewconfirm",
        params: {
          payload: {
            ...renewform,
          },
        },
      });
    },

    handleSelectionChange(val) {
      console.log("handleSelectionChange #1: " + JSON.stringify(val));
      this.selectorigin = val;
      this.selection = [];
      for (let item of val) {
        let renewform = {
          product_type: "Phone",
          region: item.IpRegion,
          config_type: item.ProductId,
          name: item.Id,
          group: item.GroupName,
          status: item.State,
          orderGroupId: item.orderGroupId,
        };
        this.selection.push(renewform);
      }
    },
    handleMatrixSelectionChange(val) {
      this.matrixselectorigin = val;
      this.matrixselection = [];
      this.matrixIdselection = [];

      for (let item of val) {
        this.matrixselection.push(item);
        this.matrixIdselection.push(item.MatrixGroupId);
      }
    },

    getUploadMatrixGroupPhoneList(row) {
      return new Promise(async (resolve, reject) => {
        this.matrixphoneselection = [];
        for (let item of row) {
          let data = {
            MatrixGroupId: item.MatrixGroupId,
          };
          try {
            const res = await this.$store.dispatch("group/getphonelist", {
              ...data,
            });
            if (res.RetCode == 0) {
              for (let eitem of res.PhoneInfos) {
                let renewform = {
                  product_type: "Phone",
                  region: eitem.IpRegion,
                  config_type: eitem.ProductId,
                  name: eitem.Id,
                  group: eitem.GroupName,
                  status: eitem.State,
                  orderGroupId: eitem.orderGroupId,
                  teamGroupId: eitem.TeamGroupId,
                  userId: eitem.UserId,
                  ownerUserId: eitem.OwnerUserId,
                };
                this.matrixphoneselection.push(renewform);
              }
            }
          } catch (error) {}
        }
        resolve(this.matrixphoneselection);
      });
    },
    getMatrixGroupPhoneList(row) {
      return new Promise(async (resolve, reject) => {
        this.matrixphoneselection = [];
        for (let item of row) {
          let data = {
            MatrixGroupId: item.MatrixGroupId,
          };
          try {
            const res = await this.$store.dispatch("group/getphonelist", {
              ...data,
            });
            if (res.RetCode === 0) {
              this.matrixphoneselection.push(...res.PhoneInfos);
            }
          } catch (error) {}
        }
        resolve(this.matrixphoneselection);
      });
    },
    //获取IP地域
    getPhoneRegions() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("pay/getipregionlist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.IpRegions);
            this.phoneRegions = res.IpRegions;
          }
        });
      });
    },
    //获取子账号列表
    getsubaccountlist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("sub/getsublist").then((res) => {
          if (res.RetCode == 0) {
            if (this.$store.getters.subAccount) {
              var list = matrixphoneList.filter((item) => {
                if (item.prop == "SubAccount") {
                  return false;
                }
                return true;
              });
              this.matrixphoneList = list;
            } else {
              matrixphoneList.forEach((item) => {
                if (item.prop == "SubAccount") {
                  item.filters = this.$store.getters.filterlist;
                }
              });
              this.matrixphoneList = matrixphoneList;
            }
            resolve(res.UserInfos);
          }
        });
      });
    },

    //获取矩阵组列表
    getmatrixgrouplist() {
      Promise.all([this.getsubaccountlist()]).then(([UserInfos]) => {
        this.$store.dispatch("group/getmatrixgrouplist").then((res) => {
          if (res.RetCode == 0) {
            this.matrixGroups = res.MatrixGroups;
            this.matrixGroups.forEach((item) => {
              UserInfos.forEach((uitem, uindex) => {
                if (item.UserId == uitem.UserId) {
                  item.SubAccount = uitem.Username;
                }
              });
              //计算剩余时间
              var timestampNow = new Date().getTime() / 1000; //second
              var remainTime = item.ExpireTime - timestampNow;
              item.RemainTimeStamp = remainTime;
              var remainText = this.secondToDate(remainTime);
              if (remainTime < 0) {
                item.RemainTime = "--------";
              } else {
                item.RemainTime = remainText;
              }
            });
          }
        });
        this.isMatrixSearch = false;
      });
    },
    //获取云手机列表
    gettablelist() {
      return new Promise((resolve, reject) => {
        let data = {
          MatrixGroupId: this.currentMatrixGroupId,
        };
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.PhoneInfos);
          }
        });
      });
    },

    //获取矩阵云手机列表
    getalltablelist() {
      return new Promise((resolve, reject) => {
        let data = {
          IsMatrix: "1",
        };
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.PhoneInfos);
          }
        });
      });
    },

    getphonelist() {
      this.loading = true;
      Promise.all([
        this.gettablelist(),
        this.getsubaccountlist(),
        this.getPhoneRegions(),
      ]).then(([PhoneInfos, UserInfos, IpRegions]) => {
        this.loading = false;
        PhoneInfos.forEach((pitem, pindex) => {
          if (pitem.ProductId == "phone-noip" && pitem.IpConfig !== null) {
            pitem.IP = pitem.IpConfig.Socks.Uri;
          }
          UserInfos.forEach((uitem, uindex) => {
            if (pitem.UserId == uitem.UserId) {
              pitem.SubAccount = uitem.Username;
            }
          });
        });
        this.tabledata = this.sortMethod(PhoneInfos);
        this.tabledata.sort(function (a, b) {
          return a.Name > b.Name ? 1 : -1;
        });
        console.log("jjjjjjj",this.tabledata.length)
        this.remindTimeList = [];
        this.teamTableData = [];
        this.workGroup1 = [];
        this.workGroup2 = [];
        this.workGroup3 = [];
        this.workGroup4 = [];
        this.workGroup5 = [];
        this.workGroup6 = [];
        this.workGroup7 = [];
        this.workGroup8 = [];
        this.workGroup9 = [];
        this.workGroup10 = [];
        this.workGroup11 = [];
        this.workGroup12 = [];
        this.workGroup13 = [];
        this.workGroup14 = [];
        this.workGroup15 = [];
        this.workGroup16 = [];

        this.tabledata.forEach((item) => {
          //计算剩余时间
          var timestampNow = new Date().getTime() / 1000; //second
          var remainTime = item.ExpireTime - timestampNow;
          item.RemainTimeStamp = remainTime;
          var remainText = this.secondToDate(remainTime);
          if (remainTime < 0) {
            item.RemainTime = "--------";
          } else {
            item.RemainTime = remainText;
          }
          this.remindTimeList.push({ id: item.Id, time: remainTime });
          if (item.State != "CREATE_FAILED") {
            //筛选创建失败
            if (item.TeamGroupId == "0") {
              this.workGroup1.push(item.Id);
            } else if (item.TeamGroupId == "1") {
              this.workGroup2.push(item.Id);
            } else if (item.TeamGroupId == "2") {
              this.workGroup3.push(item.Id);
            } else if (item.TeamGroupId == "3") {
              this.workGroup4.push(item.Id);
            } else if (item.TeamGroupId == "4") {
              this.workGroup5.push(item.Id);
            } else if (item.TeamGroupId == "5") {
              this.workGroup6.push(item.Id);
            } else if (item.TeamGroupId == "6") {
              this.workGroup7.push(item.Id);
            } else if (item.TeamGroupId == "7") {
              this.workGroup8.push(item.Id);
            } else if (item.TeamGroupId == "8") {
              this.workGroup9.push(item.Id);
            } else if (item.TeamGroupId == "9") {
              this.workGroup10.push(item.Id);
            } else if (item.TeamGroupId == "10") {
              this.workGroup11.push(item.Id);
            } else if (item.TeamGroupId == "11") {
              this.workGroup12.push(item.Id);
            } else if (item.TeamGroupId == "12") {
              this.workGroup13.push(item.Id);
            } else if (item.TeamGroupId == "13") {
              this.workGroup14.push(item.Id);
            } else if (item.TeamGroupId == "14") {
              this.workGroup15.push(item.Id);
            } else {
              this.workGroup16.push(item.Id);
            }
          }
        });

        this.tabledata.sort(function (a, b) {
          return a.State > b.State ? 1 : -1;
        });
        this.tabledatabak = this.tabledata; //保存一份，后面筛选使用，避免频繁请求后台

        if (this.needReminder) {
          this.needReminder = false;
          this.checkPhoneIndate();
        }
        console.log("jjjjjjj2",this.tabledata.length)

      });
      this.isSearch = false;
    },

    handleSearch() {
      if (this.query != null && this.query.length > 0) {
        this.searchphonelist();
      } else {
        this.getphonelist();
      }
    },
    handleMatrixSearch() {
      if (this.matrixquery != null && this.matrixquery.length > 0) {
        this.searchmatrixphonelist();
      } else {
        this.getmatrixgrouplist();
      }
    },
    getProduction(val) {
      try {
        productions.forEach((item, index) => {
          var name = "";
          if (item.ProductId == val) {
            name = item.Name;
            throw name;
          }
        });
      } catch (name) {
        if (name) {
          return name;
        }
      }
    },
    getsearchregion(val) {
      let region = val;
      try {
        this.phoneRegions.forEach((item, index) => {
          if (item.RegionId == val) {
            throw item.Name;
          }
        });
      } catch (name) {
        region = name;
      }
      return region;
    },
    getphonestatus(val) {
      let status;
      let statusindex;
      if (
        phonestatus.some((item, index) => {
          statusindex = index;
          return item.status == val;
        })
      ) {
        status = phonestatus[statusindex].statusname;
      } else {
        status = val;
      }
      return status;
    },

    searchphonelist() {
      var list = this.tabledata.filter((item) => {
        if (
          item.Name.includes(this.query) ||
          item.Id.includes(this.query) ||
          item.GroupName.includes(this.query) ||
          this.getProduction(item.ProductId).includes(this.query) ||
          this.getsearchregion(item.IpRegion).includes(this.query) ||
          this.getphonestatus(item.State).includes(this.query) ||
          item.IP.includes(this.query)
        ) {
          return true;
        }
      });
      this.tabledata = list;
      this.isSearch = true;
    },
    searchmatrixphonelist() {
      var list = this.matrixGroups.filter((item) => {
        if (
          item.Name.includes(this.matrixquery) ||
          item.MatrixGroupId.includes(this.matrixquery) ||
          item.GroupName.includes(this.matrixquery) ||
          this.getsearchregion(item.IpRegion).includes(this.matrixquery)
        ) {
          return true;
        }
      });
      this.matrixGroups = list;
      this.isMatrixSearch = true;
    },
    checkPhoneIndate() {
      if (this.remindTimeList != undefined && this.remindTimeList.length > 0) {
        this.remindTimeList.forEach((item) => {
          if (item.time < this.remindTime) {
            this.remindContent += item.id;
            this.remindContent += ",";
          }
        });
        if (this.remindContent != "") {
          this.remindContent +=
            " 即将到期，请注意及时续费，避免云手机被删除引起数据丢失！";
          //console.log("remind message: " + this.remindContent);
          var text =
            '<span style="font-style: normal;color: #5897F2;" id="messageTrack">' +
            this.remindContent +
            "</span>";
          this.$notify({
            title: "即将到期提醒",
            dangerouslyUseHTMLString: true,
            message: text,
            position: "bottom-right",
            duration: 5000,
          });
        }
      }
    },
    secondToDate: function (msd) {
      var time = Math.abs(msd);
      if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
          //1小时以内
          time =
            parseInt(time / 60.0) +
            "分钟" +
            parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
            "秒";
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          //1天以内
          time =
            parseInt(time / 3600.0) +
            "小时" +
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) +
            "分钟";
          // + parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time >= 60 * 60 * 24) {
          //超过一天
          time =
            parseInt(time / 3600.0 / 24) +
            "天" +
            parseInt(
              (parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) *
                24
            ) +
            "小时";
          // + parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) + "分钟" +
          // parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
          // parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
        } else if (time < 60) {
          time = parseInt(time) + "秒";
        }
      }
      if (msd >= 0) {
        return time;
      } else {
        return `-${time}`;
      }
    },

    selectSubAccount(command) {
      const findUser = this.subAccounts.find((item) => item.UserId == command);
      this.currentAccount = findUser.Username;
      if (this.tabledatabak.length != 0) {
        if (command == "all") {
          this.tabledata = this.tabledatabak;
        } else {
          this.tabledata = this.tabledatabak.filter(
            (item) => item.UserId == command
          );
        }
      }
    },
    cellmouseenter(row) {
      row.isShowBtn = true;
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },
    cellmouseleave(row) {
      this.tabledata = this.tabledata.map((n) => {
        n.isShowBtn = false;
        return n;
      });
      let Arr = JSON.parse(JSON.stringify(this.tabledata));
      this.tabledata = JSON.parse(JSON.stringify(Arr));
    },

    cellmouseentermatrix(row) {
      row.isShowBtn = true;
      let Arr = JSON.parse(JSON.stringify(this.matrixGroups));
      this.matrixGroups = JSON.parse(JSON.stringify(Arr));
    },
    cellmouseleavematrix(row) {
      this.matrixGroups = this.matrixGroups.map((n) => {
        n.isShowBtn = false;
        return n;
      });
      let Arr = JSON.parse(JSON.stringify(this.matrixGroups));
      this.matrixGroups = JSON.parse(JSON.stringify(Arr));
    },
    workgroupchangeclick() {},
    changeworkgroup(row) {
      this.matrixGroupIds = [row.MatrixGroupId];
      this.currentWorkGroupId = row.workGroupId;
      let data = {
        MatrixGroupId: row.MatrixGroupId,
      };
      this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.workGroup1 = [];
          this.workGroup2 = [];
          this.workGroup3 = [];
          this.workGroup4 = [];
          this.workGroup5 = [];
          this.workGroup6 = [];
          this.workGroup7 = [];
          this.workGroup8 = [];
          this.workGroup9 = [];
          this.workGroup10 = [];
          this.workGroup11 = [];
          this.workGroup12 = [];
          this.workGroup13 = [];
          this.workGroup14 = [];
          this.workGroup15 = [];
          this.workGroup16 = [];

          try {
            res.PhoneInfos.forEach((item) => {
              //子账号
              if (this.subAccount) {
                if (item.State != "CREATE_FAILED") {
                  //筛选创建失败
                  if (item.TeamGroupId == "0") {
                    this.workGroup1.push(item.Id);
                  } else if (item.TeamGroupId == "1") {
                    this.workGroup2.push(item.Id);
                  } else if (item.TeamGroupId == "2") {
                    this.workGroup3.push(item.Id);
                  } else if (item.TeamGroupId == "3") {
                    this.workGroup4.push(item.Id);
                  } else if (item.TeamGroupId == "4") {
                    this.workGroup5.push(item.Id);
                  } else if (item.TeamGroupId == "5") {
                    this.workGroup6.push(item.Id);
                  } else if (item.TeamGroupId == "6") {
                    this.workGroup7.push(item.Id);
                  } else if (item.TeamGroupId == "7") {
                    this.workGroup8.push(item.Id);
                  } else if (item.TeamGroupId == "8") {
                    this.workGroup9.push(item.Id);
                  } else if (item.TeamGroupId == "9") {
                    this.workGroup10.push(item.Id);
                  } else if (item.TeamGroupId == "10") {
                    this.workGroup11.push(item.Id);
                  } else if (item.TeamGroupId == "11") {
                    this.workGroup12.push(item.Id);
                  } else if (item.TeamGroupId == "12") {
                    this.workGroup13.push(item.Id);
                  } else if (item.TeamGroupId == "13") {
                    this.workGroup14.push(item.Id);
                  } else if (item.TeamGroupId == "14") {
                    this.workGroup15.push(item.Id);
                  } else {
                    this.workGroup16.push(item.Id);
                  }
                }
              } else {
                if (
                  item.State != "CREATE_FAILED" &&
                  item.UserId == item.OwnerUserId
                ) {
                  //筛选创建失败和子账号
                  if (item.TeamGroupId == "0") {
                    this.workGroup1.push(item.Id);
                  } else if (item.TeamGroupId == "1") {
                    this.workGroup2.push(item.Id);
                  } else if (item.TeamGroupId == "2") {
                    this.workGroup3.push(item.Id);
                  } else if (item.TeamGroupId == "3") {
                    this.workGroup4.push(item.Id);
                  } else if (item.TeamGroupId == "4") {
                    this.workGroup5.push(item.Id);
                  } else if (item.TeamGroupId == "5") {
                    this.workGroup6.push(item.Id);
                  } else if (item.TeamGroupId == "6") {
                    this.workGroup7.push(item.Id);
                  } else if (item.TeamGroupId == "7") {
                    this.workGroup8.push(item.Id);
                  } else if (item.TeamGroupId == "8") {
                    this.workGroup9.push(item.Id);
                  } else if (item.TeamGroupId == "9") {
                    this.workGroup10.push(item.Id);
                  } else if (item.TeamGroupId == "10") {
                    this.workGroup11.push(item.Id);
                  } else if (item.TeamGroupId == "11") {
                    this.workGroup12.push(item.Id);
                  } else if (item.TeamGroupId == "12") {
                    this.workGroup13.push(item.Id);
                  } else if (item.TeamGroupId == "13") {
                    this.workGroup14.push(item.Id);
                  } else if (item.TeamGroupId == "14") {
                    this.workGroup15.push(item.Id);
                  } else {
                    this.workGroup16.push(item.Id);
                  }
                }
              }
              //判断状态如果有一个不是处于下面状态
              if (
                item.State != "RUNNING" &&
                item.State != "STOPPED" &&
                item.State != "CREATE_FAILED"
              ) {
                throw new error();
              }
            });
          } catch (error) {
            this.$message.warning(
              "当前存在云手机不处于”运行中“或”已关机“状态，暂时不能切换工作组，请稍后再试~"
            );
            return;
          }
          this.showworkgroupchange = !this.showworkgroupchange;
        } else {
          this.$message.warning("当前矩阵组不存在云手机，请检查后切换~");
        }
      });
    },

    changecheckworkgroup() {
      if (Object.entries(this.matrixselection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }

      for (let i = 0; i < this.matrixselection.length; i++) {
        for (let j = 1; j < this.matrixselection.length; j++) {
          if (
            this.matrixselection[i].ProductId !=
            this.matrixselection[j].ProductId
          ) {
            this.$message.warning("请选择同一规格的项目进行切换~");
            return;
          }
        }
      }
      this.currentProductId = this.matrixselection[0].ProductId;
      this.matrixGroupIds = this.matrixIdselection;
      this.workGroup1 = [];
      this.workGroup2 = [];
      this.workGroup3 = [];
      this.workGroup4 = [];
      this.workGroup5 = [];
      this.workGroup6 = [];
      this.workGroup7 = [];
      this.workGroup8 = [];
      this.workGroup9 = [];
      this.workGroup10 = [];
      this.workGroup11 = [];
      this.workGroup12 = [];
      this.workGroup13 = [];
      this.workGroup14 = [];
      this.workGroup15 = [];
      this.workGroup16 = [];

      Promise.all([
        this.getUploadMatrixGroupPhoneList(this.matrixselection),
      ]).then(([PhoneInfos]) => {
        try {
          PhoneInfos.forEach((item) => {
            //子账号
            if (this.subAccount) {
              if (item.status != "CREATE_FAILED") {
                //筛选创建失败
                if (item.teamGroupId == "0") {
                  this.workGroup1.push(item.name);
                } else if (item.teamGroupId == "1") {
                  this.workGroup2.push(item.name);
                } else if (item.teamGroupId == "2") {
                  this.workGroup3.push(item.name);
                } else if (item.teamGroupId == "3") {
                  this.workGroup4.push(item.name);
                } else if (item.teamGroupId == "4") {
                  this.workGroup5.push(item.name);
                } else if (item.teamGroupId == "5") {
                  this.workGroup6.push(item.name);
                } else if (item.teamGroupId == "6") {
                  this.workGroup7.push(item.name);
                } else if (item.teamGroupId == "7") {
                  this.workGroup8.push(item.name);
                } else if (item.teamGroupId == "8") {
                  this.workGroup9.push(item.name);
                } else if (item.teamGroupId == "9") {
                  this.workGroup10.push(item.name);
                } else if (item.teamGroupId == "10") {
                  this.workGroup11.push(item.name);
                } else if (item.teamGroupId == "11") {
                  this.workGroup12.push(item.name);
                } else if (item.teamGroupId == "12") {
                  this.workGroup13.push(item.name);
                } else if (item.teamGroupId == "13") {
                  this.workGroup14.push(item.name);
                } else if (item.teamGroupId == "14") {
                  this.workGroup15.push(item.name);
                } else {
                  this.workGroup16.push(item.name);
                }
              }
            } else {
              if (
                item.status != "CREATE_FAILED" &&
                item.userId == item.ownerUserId
              ) {
                //筛选创建失败
                 if (item.teamGroupId == "0") {
                  this.workGroup1.push(item.name);
                } else if (item.teamGroupId == "1") {
                  this.workGroup2.push(item.name);
                } else if (item.teamGroupId == "2") {
                  this.workGroup3.push(item.name);
                } else if (item.teamGroupId == "3") {
                  this.workGroup4.push(item.name);
                } else if (item.teamGroupId == "4") {
                  this.workGroup5.push(item.name);
                } else if (item.teamGroupId == "5") {
                  this.workGroup6.push(item.name);
                } else if (item.teamGroupId == "6") {
                  this.workGroup7.push(item.name);
                } else if (item.teamGroupId == "7") {
                  this.workGroup8.push(item.name);
                } else if (item.teamGroupId == "8") {
                  this.workGroup9.push(item.name);
                } else if (item.teamGroupId == "9") {
                  this.workGroup10.push(item.name);
                } else if (item.teamGroupId == "10") {
                  this.workGroup11.push(item.name);
                } else if (item.teamGroupId == "11") {
                  this.workGroup12.push(item.name);
                } else if (item.teamGroupId == "12") {
                  this.workGroup13.push(item.name);
                } else if (item.teamGroupId == "13") {
                  this.workGroup14.push(item.name);
                } else if (item.teamGroupId == "14") {
                  this.workGroup15.push(item.name);
                } else {
                  this.workGroup16.push(item.name);
                }
              }
            }
            // console.log("popopopo",this.workGroup1.length,this.workGroup2.length,this.workGroup3.length,this.workGroup4.length)

            //判断状态如果有一个不是处于下面状态
            if (
              item.status != "RUNNING" &&
              item.status != "STOPPED" &&
              item.status != "CREATE_FAILED"
            ) {
              throw new error();
            }
          });
        } catch (error) {
          this.$message.warning(
            "当前存在云手机不处于”运行中“或”已关机“状态，暂时不能切换工作组，请稍后再试~"
          );
          return;
        }

        if (
          this.workGroup1.length == 0 &&
          this.workGroup2.length == 0 &&
          this.workGroup3.length == 0 &&
          this.workGroup4.length == 0 &&
          this.workGroup5.length == 0 &&
          this.workGroup6.length == 0 &&
          this.workGroup7.length == 0 &&
          this.workGroup8.length == 0 &&
          this.workGroup9.length == 0 &&
          this.workGroup10.length == 0 &&
          this.workGroup11.length == 0 &&
          this.workGroup12.length == 0 &&
          this.workGroup13.length == 0 &&
          this.workGroup14.length == 0 &&
          this.workGroup15.length == 0 &&
          this.workGroup16.length == 0
        ) {
          this.$message.warning(
            "请检查是否勾选了当前主账号下面矩阵组，子账号下面的矩阵组不能切换~"
          );
          return;
        }
        this.showworkgroupchange = !this.showworkgroupchange;
      });
    },

    closeworkgroupchange() {
      this.handleMatrixSearch();
      this.showworkgroupchange = !this.showworkgroupchange;
    },
    closematrixrenew() {
      this.showmatrixrenew = !this.showmatrixrenew;
    },

    showbatchsetipmodel() {
      if (Object.entries(this.matrixselection).length == 0) {
        this.$message.warning("至少需选择一个项目");
        return;
      }
      this.rowlist = [];
      Promise.all([
        this.getUploadMatrixGroupPhoneList(this.matrixselection),
      ]).then(([PhoneInfos]) => {
        this.rowlist = PhoneInfos;
        this.showbatchsetip = !this.showbatchsetip;
      });
    },

    closebatchsetipmodel() {
      this.showbatchsetip = !this.showbatchsetip;
    },

    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
  mounted() {
    window.editename = this.editename;
    window.editematrixname = this.editematrixname;
    window.editegroup = this.editegroup;
  },

  activated() {
    this.getrefreshtoken();
    this.getmatrixgrouplist();
    // this.getmatrixphonelist();
    this.getuserinfo();
  },
  deactivated() {
    clearInterval(this.timer);
    this.$notify.close();
  },
  //跳转的路由name
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "login" || to.query.reminder == "true") {
        vm.needReminder = true;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>