<template>
  <models
    :showmodel="showsetip"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmipregion"
    @closemodel="closeshowipregion"
    class="setipmodel"
    @open="open"
  >
    <div class="warningtitle">
      <p class="warningcontent">只针对无网版手机配置海外IP</p>
    </div>
    <el-form :model="model" ref="socksform">
      <com-table
        ref="comtable"
        :headercellstyle="headercellstyle"
        tablesize="small"
        tableheight="300"
        style="width: 100%"
        :columns="ipcolumns"
        :data="model.ipregionlist"
        @rowClassName="rowClassName"
      >
        <el-table-column
          slot="action"
          align="left"
          label="IP地址:IP端口:IP账号:IP密码"
          width="400"
          prop="IPMsg"
        >
          <template slot-scope="scope">
            <el-form-item
              :prop="`ipregionlist[${scope.$index}].IPMsg`"
              :rules="[
                {
                  required: true,
                  message: 'IP地址:IP端口:IP账号:IP密码不能为空',
                  trigger: 'blur',
                },
                {
                  pattern: /^[^\u4e00-\u9fa5]+$/,
                  message: '不能存在中文',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                :clearable="true"
                size="mini"
                v-model.trim="scope.row.IPMsg"
                placeholder="请输入IP地址:IP端口:IP账号:IP密码"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          slot="action"
          align="left"
          label="出口IP地址"
          width="200"
          prop="exportIP"
        >
          <template slot-scope="scope">
            <el-input
              :clearable="true"
              size="mini"
              v-model.trim="scope.row.exportIp"
              placeholder="出口IP地址（选填）"
            ></el-input>
          </template>
        </el-table-column>
      </com-table>
    </el-form>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import ComTable from "../../../components/Table/index.vue";
export default {
  props: {
    showsetip: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Object,
    },
  },
  components: { Models, ComTable },
  data() {
    return {
      ipcolumns: [
        {
          prop: "xh",
          label: "序号",
          width: "60",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "phoneId",
          label: "手机ID",
          width: "150",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "socks5",
          label: "协议",
          width: "80",
          align: "left",
          headerAlign: "left",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      model: {
        ipregionlist: [],
      },
      alreadychecked: {},
      ipregionlist: [],
      checkedDetail: [],
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
      showhistroyip: false,
    };
  },
  watch: {
    row(val) {
      this.alreadychecked = val;
    },
  },
  methods: {
    open() {
      let obj = {
        phoneId: this.alreadychecked.Id,
        IPMsg: "",
        IP: "",
        dk: "",
        account: "",
        password: "",
        exportIp: "",
      };
      this.model.ipregionlist.push(obj);
      console.log(this.model.ipregionlist);
    },
    rowClassName(row, rowIndex) {
      row.xh = rowIndex + 1;
      row.socks5 = "socks5";
    },
    confirmipregion() {
      this.$refs.socksform.validate((valid) => {
        if (valid) {
          let payload = {
            Configs: [],
          };
          payload.Configs = this.model.ipregionlist.map((item, index) => {
            let obj = {};
            obj.PhoneId = item.phoneId;
            const strArr = item.IPMsg.split(":", 4);
            if (strArr.length == 4) {
              obj.SocksConfig = {
                Uri: strArr[0],
                Port: Number(strArr[1]),
                UserName: strArr[2],
                Password: strArr[3],
                ExportIp: item.exportIp,
              };
            } else if (strArr.length < 4 && strArr.length >= 2) {
              obj.SocksConfig = {
                Uri: strArr[0],
                Port: Number(strArr[1]),
                UserName: "",
                Password: "",
                ExportIp: item.exportIp,
              };
            } else {
              this.$message.error(
                "请按照如下格式输入（IP地址:IP端口:IP账号:IP密码）"
              );
            }

            return obj;
          });

          if (
            (payload.Configs[0].SocksConfig.Password == "" &&
              payload.Configs[0].SocksConfig.UserName == "") ||
            (payload.Configs[0].SocksConfig.Password != "" &&
              payload.Configs[0].SocksConfig.UserName != "")
          ) {
            this.$store
              .dispatch("control/phonesetsocks", { ...payload })
              .then((res) => {
                if (res.RetCode == 0) {
                  this.$message.success("正在配置IP");
                  this.closeshowipregion();
                } else {
                  this.$message.error("配置IP失败");
                }
              });
          } else {
            if (payload.Configs[0].SocksConfig.Password == "") {
              this.$message.error("请输入IP的密码~");
            } else if (payload.Configs[0].SocksConfig.UserName == "") {
              this.$message.error("请输入IP的账号~");
            }
          }

          console.log(payload);
          console.log(this.model.ipregionlist);
        }
      });
    },
    closeshowipregion() {
      this.model.ipregionlist = [];
      this.$emit("closesetipmodel");
    },
  },
};
</script>
<style lang="scss" scoped>
.setipmodel {
  ::v-deep .el-dialog {
    width: 1000px;
    height: 500px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 12px 20px;
  }
  ::v-deep .el-form-item__content {
    line-height: 50px;
    top: 10px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0;
  }
  .table-bar {
    margin-bottom: 10px;
    .table-right {
      float: right;
      color: #409eff;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        border-bottom: 1px solid #409eff;
      }
    }
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>