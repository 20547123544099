<template>
  <el-drawer
    title="我是标题"
    :size="size"
    :visible.sync="drawershow"
    :with-header="false"
    @close="handleclose"
    @open="handleopen"
  >
    <slot></slot>
  </el-drawer>
</template>
<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
    size: {
      type: String,
    },
  },
  data() {
    return {
      drawershow: this.drawer,
    };
  },
  watch: {
    drawer: function (val) {
      this.drawershow = val;
    },
  },
  methods: {
    handleclose() {
      this.$emit("handleclose");
    },
    handleopen(){
      this.$emit("handleopen")
    }
  },
};
</script>
<style lang="scss" scoped>
</style>