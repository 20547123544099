<template>
  <models
    :showmodel="showdelete"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmdelete"
    @closemodel="closedelete"
    class="ifdelete"
  >
    <div class="warningtitle">
      <p class="warningcontent">确定要删除以下云手机吗？</p>
    </div>
    <com-table
      :headercellstyle="headercellstyle"
      :columns="phoneList"
      :data="tabledata"
      tableheight="100"
      tablesize="small"
    >
    </com-table>
  </models>
</template>
<script>
import ComTable from "../../../components/Table";
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showdelete: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      phoneList: [
        {
          prop: "Name",
          label: "云手机名称",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "Id",
          label: "云手机ID",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      tabledata: [],
    };
  },
  watch: {
    row: function (val) {
      this.tabledata = [{ ...val }];
    },
  },
  components: { Models, ComTable },
  methods: {
    confirmdelete() {
      
      this.closedelete();
    },
    closedelete() {
      this.$emit("closedelete");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifdelete {
  ::v-deep .el-dialog {
    width: 600px;
    height: 400px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 230px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>