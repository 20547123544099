<template>
  <div class="upload-wrap">
    <div class="title">
      <p class="title-p">上传至云手机</p>
      <p class="close">
        <i @click="closemodel" class="el-icon-close close-i"></i>
      </p>
    </div>
    <div class="body">
      <div class="warningtitle">
        <p class="warningcontent">
          上传后的文件存放在云手机:桌面/文件/下载，如上传文件为apk则自动安装
        </p>
      </div>
      <com-table
        class="renewlist"
        :headercellstyle="headercellstyle"
        :columns="renewList"
        :data="tabledata"
        tableheight="80"
        tablesize="small"
      >
      </com-table>
      <el-upload
        ref="upload"
        :show-file-list="false"
        action="bucketUrl"
        :auto-upload="false"
        :file-list="fileList"
        :on-change="onchangefile"
        :multiple="true"
      >
      </el-upload>
      <el-button size="mini" type="primary" @click="addfile" class="uploadbtn"
        >添加文件</el-button
      >
      <com-table
        class="filecomtable"
        :data="showfileList"
        :columns="filecloumns"
        tableheight="400"
        :pagination="pagination"
        :headercellstyle="headercellstyle"
        tablesize="small"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          fixed="right"
          label="操作"
          min-width="100"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="primary"
              size="mini"
              class="deletbtn"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "../../../../components/Table";
import { renewList, filecloumns } from "../../../../utils/tableList";
import Us3Upload from "../../../../utils/us3upload";
export default {
  components: { ComTable },
  props: ["uploadrow"],
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      renewList,
      tabledata: [],
      fileList: [],
      bucketUrl: "https://wumitech-file-upload-hk.hk.ufileos.com",
      Us3Upload: null,
      less512List: [],
      more512List: [],
      form: {
        PhoneIds: [],
        FileName: "",
        URL: "",
      },
      filecloumns,
      showfileList: [],
    };
  },
  methods: {
    closemodel() {
      this.$emit("closeupload");
    },
    addfile() {
      this.form.PhoneIds = [];
      this.tabledata.forEach((item) => {
        this.form.PhoneIds.push(item.name);
      });
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    containSpecial(str) {
      var spec = /[',<>{}~!@#$%^&*]/;
      return spec.test(str);
    },
    onchangefile(file, fileList) {
      if (this.containSpecial(file.name)) {
        this.$message.warning("文件名含有特殊字符，请修改后重新选择上传.");
        return;
      }
      let size = file.size;
      if (size < 1024) {
        size = `${file.size}B`;
      }
      if (size / 1024 < 1024) {
        size = `${Math.round((size / 1024) * 100) / 100}KB`;
      }
      if (size / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024) * 100) / 100}MB`;
      }
      if (size / 1024 / 1024 / 1024 < 1024) {
        size = `${Math.round((size / 1024 / 1024 / 1024) * 100) / 100}GB`;
      }
      if (size / 1024 / 1024 / 1024 / 1024 < 1024) {
        size = `${
          Math.round((size / 1024 / 1024 / 1024 / 1024) * 100) / 100
        }TB`;
      }
      let filedata = {
        uid: file.uid,
        file: file.raw,
        filename: file.name,
        filesize: size,
        filetype: file.raw.type ? file.raw.type : "未知",
        filestate: "上传中",
        fileprogress: 0,
      };
      if (this.showfileList.length) {
        try {
          this.showfileList.forEach((item, index) => {
            if (item.filename == filedata.filename) {
              throw new error();
            }
          });
          this.showfileList.push(filedata);
          this.fileList.push(filedata.file);
          this.submit();
        } catch (error) {
          // console.log(item);
          // console.log(filedata);
        }
      } else {
        this.showfileList.push(filedata);
        this.fileList.push(filedata.file);
        this.submit();
      }
    },
    submit() {
      if (!this.Us3Upload) {
        this.Us3Upload = new Us3Upload(
          "wumitech-file-upload-hk",
          this.bucketUrl,
          "hk"
        );
      }
      this.fileList.forEach((item, index) => {
        if (item.size < 512 * 1024 * 1024) {
          if (this.less512List.length) {
            this.less512List.forEach((eitem, eindex) => {
              if (eitem.filename !== item.name) {
                this.less512List = [item];
              }
            });
          } else {
            this.less512List = [item];
          }
        } else {
          if (this.more512List.length) {
            this.more512List.forEach((mitem, mindex) => {
              if (mitem.filename !== item.name) {
                this.more512List = [item];
              }
            });
          } else {
            this.more512List = [item];
          }
        }
      });
      if (this.less512List.length) {
        console.log(this.less512List);
        this.commonUpload();
      }
      if (this.more512List.length) {
        this.multipartUpload();
      }
    },
    commonUpload() {
      var that = this;
      var successCallBack = function (res) {
        console.log(JSON.stringify(res));
        that.downloadToPhone(res);
      };
      var errorCallBack = function (res) {
        console.error("errorCallBack", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.progress * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = percentComplete;
              item.filestate = "正在同步至云手机";
            } else {
              item.fileprogress = percentComplete;
              item.filestate = "上传中";
            }
          }
        });
      };
      this.Us3Upload.batchUpload(
        this.less512List,
        successCallBack,
        errorCallBack,
        progressCallBack
      );
    },
    multipartUpload() {
      var successList = [];
      var currentIndex = 0;
      var that = this;
      var successCallBack = function (res) {
        successList.push(res.file);
        if (that.more512List.length !== successList.length) {
          currentIndex++;
          that.Us3Upload.sliceUpload(
            {
              file: that.more512List[currentIndex],
            },
            successCallBack,
            errorCallBack,
            progressCallBack
          );
        }
        console.log(res);
        that.downloadToPhone(res);
      };
      var errorCallBack = function (res) {
        console.error("multipartUpload errorCallBack: ", res);
      };
      var progressCallBack = function (res) {
        var percent = parseFloat(res.value * 100).toFixed(1);
        var percentComplete = percent + "%";
        // console.log(percentComplete);
        that.showfileList.forEach((item) => {
          if (item.uid == res.fileUid) {
            if (percentComplete == "100.0%") {
              item.fileprogress = percentComplete;
              item.filestate = "正在同步至云手机";
            } else {
              item.filestate = "上传中";
              item.fileprogress = percentComplete;
            }
          }
        });
      };
      this.Us3Upload.sliceUpload(
        {
          file: this.more512List[currentIndex],
        },
        successCallBack,
        errorCallBack,
        progressCallBack
      );
      this.GDUs3Upload.sliceUpload(
        {
          file: this.more512List[currentIndex],
        },
        successCallBack,
        errorCallBack,
        progressCallBack
      );
    },
    downloadToPhone(data) {
      var that = this;
      if (this.Us3Upload == null && this.GDUs3Upload == null) {
        return;
      }
      var request = {
        fileName: data.fileName,
        fileUid: data.fileUid,
        fileType: data.fileType,
      };
      var successCallBack = function (res) {
        that.form.FileName = request.fileName;
        that.form.URL = res;
        that.form.uid = request.fileUid;
        console.log(res);
        that.uploadfiles(request);
      };
      var errorCallBack = function (res) {
        console.error("errorCallBack", res);
      };
      that.Us3Upload.getDownloadUrl(request, successCallBack, errorCallBack);
    },
    uploadfiles(request) {
      let data = {
        ...this.form,
      };
      var successCallBack = function (res) {
        console.log(res);
      };
      var errorCallBack = function (res) {
        console.log(res);
      };
      this.$store.dispatch("control/importfiles", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.showfileList.forEach((item, index) => {
            if (item.uid == data.uid) {
              item.filestate = "上传成功";
              item.fileprogress = "100%";
            }
          });
          this.showfileList = this.showfileList.filter((item) => {
            if (item.fileprogress == "100%") {
              return false;
            } else {
              return true;
            }
          });
          // this.Us3Upload.deleteFile(
          //   request.fileName,
          //   request.fileType,
          //   successCallBack,
          //   errorCallBack
          // );
        }
      });
    },
    handleClick(row) {
      this.showfileList.forEach((item, index) => {
        if (item.uid == row.uid) {
          this.showfileList.splice(index, 1);
          this.fileList.splice(index, 1);
        }
      });
    },
  },
  mounted() {
    this.tabledata = this.uploadrow;
  },
};
</script>
<style lang="scss" scoped>
.upload-wrap {
  width: 600px;
  height: 746px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  .title {
    height: 36px;
    border-bottom: 1px solid #eee;
    display: flex;
    .title-p {
      font-size: 14px;
      flex: 1;
      line-height: 36px;
      box-sizing: border-box;
      padding-left: 10px;
      color: #000;
    }
    .close {
      width: 25px;
      height: 100%;
      line-height: 36px;
      font-size: 16px;
      .close-i {
        cursor: pointer;
      }
    }
  }
  .body {
    width: 100%;
    height: calc(100% - 36px);
    box-sizing: border-box;
    padding: 10px;
    overflow-y: auto;
    .warningtitle {
      height: 40px;
      margin-bottom: 20px;
      background-color: rgba(228, 229, 241);
      line-height: 40px;
      color: rgb(102, 102, 102);
      font-size: 14px;
      .warningcontent {
        margin-left: 20px;
      }
    }
    .uploadbtn {
      color: #fff;
      margin-bottom: 15px;
      border-radius: 5px;
      box-shadow: rgb(92 118 232) 0px 2px 4px -1px,
        rgb(0 0 0 / 12%) 0px -3px 0px 0px inset,
        rgb(0 0 0 / 12%) 0px 1px 0px 0px inset;
      background: linear-gradient(
        135deg,
        rgb(100, 136, 252) 0%,
        rgb(56, 96, 244) 100%
      );
    }
  }
}
</style>