<template>
  <models
    :showmodel="showworkgroupchange"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeworkgroupchange"
    class="ifeditename"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>开启工作组：</span></div>
        </el-col>
        <el-col :span="18">
          <el-select
            size="small"
            v-model="workGroupName"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showworkgroupchange: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
    workGroup1: {
      type: Array,
      default: () => [],
    },
    workGroup2: {
      type: Array,
      default: () => [],
    },
    workGroup3: {
      type: Array,
      default: () => [],
    },
    workGroup4: {
      type: Array,
      default: () => [],
    },
    workGroup5: {
      type: Array,
      default: () => [],
    },
    workGroup6: {
      type: Array,
      default: () => [],
    },
    workGroup7: {
      type: Array,
      default: () => [],
    },
    workGroup8: {
      type: Array,
      default: () => [],
    },
    workGroup9: {
      type: Array,
      default: () => [],
    },
    workGroup10: {
      type: Array,
      default: () => [],
    },
    workGroup11: {
      type: Array,
      default: () => [],
    },
    workGroup12: {
      type: Array,
      default: () => [],
    },
    workGroup13: {
      type: Array,
      default: () => [],
    },
    workGroup14: {
      type: Array,
      default: () => [],
    },
    workGroup15: {
      type: Array,
      default: () => [],
    },
    workGroup16: {
      type: Array,
      default: () => [],
    },
    matrixGroupIds: {
      type: Array,
      default: () => [],
    },
    workGroupId: {
      type: String,
    },
    productId: {
      type: String,
    },
  },
  data() {
    return {
      options: [],

      workGroupId: "0",
      workGroupName: "工作组1",
      matrixGroupIds: [],
      productId: "",
    };
  },
  watch: {
    workGroup1(val) {
      this.workGroup1 = val;
    },
    workGroup2(val) {
      this.workGroup2 = val;
    },
    workGroup3(val) {
      this.workGroup3 = val;
    },
    workGroup4(val) {
      this.workGroup4 = val;
    },
    workGroup5(val) {
      this.workGroup5 = val;
    },
    workGroup6(val) {
      this.workGroup6 = val;
    },
    workGroup7(val) {
      this.workGroup7 = val;
    },
    workGroup8(val) {
      this.workGroup8 = val;
    },
    workGroup9(val) {
      this.workGroup9 = val;
    },
    workGroup10(val) {
      this.workGroup10 = val;
    },
    workGroup11(val) {
      this.workGroup11 = val;
    },
    workGroup12(val) {
      this.workGroup12 = val;
    },
    workGroup13(val) {
      this.workGroup13 = val;
    },
    workGroup14(val) {
      this.workGroup14 = val;
    },
    workGroup15(val) {
      this.workGroup15 = val;
    },
    workGroup16(val) {
      this.workGroup16 = val;
    },
    workGroupId: function (val) {
      this.workGroupId = val;
      if (this.workGroupId == "1") {
        this.workGroupName = "工作组2";
      } else if (this.workGroupId == "2") {
        this.workGroupName = "工作组3";
      } else if (this.workGroupId == "3") {
        this.workGroupName = "工作组4";
      } else if (this.workGroupId == "4") {
        this.workGroupName = "工作组5";
      } else if (this.workGroupId == "5") {
        this.workGroupName = "工作组6";
      } else if (this.workGroupId == "6") {
        this.workGroupName = "工作组7";
      } else if (this.workGroupId == "7") {
        this.workGroupName = "工作组8";
      } else if (this.workGroupId == "8") {
        this.workGroupName = "工作组9";
      }else if (this.workGroupId == "9") {
        this.workGroupName = "工作组10";
      }else if (this.workGroupId == "10") {
        this.workGroupName = "工作组11";
      }else if (this.workGroupId == "11") {
        this.workGroupName = "工作组12";
      }else if (this.workGroupId == "12") {
        this.workGroupName = "工作组13";
      }else if (this.workGroupId == "13") {
        this.workGroupName = "工作组14";
      }else if (this.workGroupId == "14") {
        this.workGroupName = "工作组15";
      }else if (this.workGroupId == "15") {
        this.workGroupName = "工作组16";
      }else {
        this.workGroupName = "工作组1";
      }
    },
    productId: function (val) {
      this.productId = val;
      if (this.productId == "phone-matrix") {
        this.options = [
          { value: 0, label: "工作组1" },
          { value: 1, label: "工作组2" },
          { value: 2, label: "工作组3" },
          { value: 3, label: "工作组4" },
        ];
      } else if (this.productId == "phone-matrix8") {
        this.options = [
          { value: 0, label: "工作组1" },
          { value: 1, label: "工作组2" },
          { value: 2, label: "工作组3" },
          { value: 3, label: "工作组4" },
          { value: 4, label: "工作组5" },
          { value: 5, label: "工作组6" },
          { value: 6, label: "工作组7" },
          { value: 7, label: "工作组8" },
        ];
      } else {
        this.options = [
          { value: 0, label: "工作组1" },
          { value: 1, label: "工作组2" },
          { value: 2, label: "工作组3" },
          { value: 3, label: "工作组4" },
          { value: 4, label: "工作组5" },
          { value: 5, label: "工作组6" },
          { value: 6, label: "工作组7" },
          { value: 7, label: "工作组8" },
          { value: 8, label: "工作组9" },
          { value: 9, label: "工作组10" },
          { value: 10, label: "工作组11" },
          { value: 11, label: "工作组12" },
          { value: 12, label: "工作组13" },
          { value: 13, label: "工作组14" },
          { value: 14, label: "工作组15" },
          { value: 15, label: "工作组16" },
        ];
      }
    },
    matrixGroupIds(val) {
      this.matrixGroupIds = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    async confirmedit() {
      let onPhoneIds = [];
      let offPhoneIds = [];
      if (this.productId == "phone-matrix") {
        if (this.workGroupName == "1") {
          this.workGroupId = "1";
          onPhoneIds = this.workGroup2;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup3,
            this.workGroup4
          );
        } else if (this.workGroupName == "2") {
          this.workGroupId = "2";
          onPhoneIds = this.workGroup3;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup4
          );
        } else if (this.workGroupName == "3") {
          this.workGroupId = "3";
          onPhoneIds = this.workGroup4;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3
          );
        } else {
          this.workGroupId = "0";
          onPhoneIds = this.workGroup1;
          offPhoneIds = this.workGroup2.concat(
            this.workGroup3,
            this.workGroup4
          );
        }
      } else if (this.productId == "phone-matrix8") {
        if (this.workGroupName == "1") {
          this.workGroupId = "1";
          onPhoneIds = this.workGroup2;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8
          );
        } else if (this.workGroupName == "2") {
          this.workGroupId = "2";
          onPhoneIds = this.workGroup3;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8
          );
        } else if (this.workGroupName == "3") {
          this.workGroupId = "3";
          onPhoneIds = this.workGroup4;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8
          );
        } else if (this.workGroupName == "4") {
          this.workGroupId = "4";
          onPhoneIds = this.workGroup5;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8
          );
        } else if (this.workGroupName == "5") {
          this.workGroupId = "5";
          onPhoneIds = this.workGroup6;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup7,
            this.workGroup8
          );
        } else if (this.workGroupName == "6") {
          this.workGroupId = "6";
          onPhoneIds = this.workGroup7;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup8
          );
        } else if (this.workGroupName == "7") {
          this.workGroupId = "7";
          onPhoneIds = this.workGroup8;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7
          );
        } else {
          this.workGroupId = "0";
          onPhoneIds = this.workGroup1;
          offPhoneIds = this.workGroup2.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8
          );
        }
      } else {
        if (this.workGroupName == "1") {
          this.workGroupId = "1";
          onPhoneIds = this.workGroup2;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "2") {
          this.workGroupId = "2";
          onPhoneIds = this.workGroup3;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "3") {
          this.workGroupId = "3";
          onPhoneIds = this.workGroup4;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "4") {
          this.workGroupId = "4";
          onPhoneIds = this.workGroup5;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "5") {
          this.workGroupId = "5";
          onPhoneIds = this.workGroup6;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "6") {
          this.workGroupId = "6";
          onPhoneIds = this.workGroup7;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "7") {
          this.workGroupId = "7";
          onPhoneIds = this.workGroup8;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "8") {
          this.workGroupId = "8";
          onPhoneIds = this.workGroup9;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "9") {
          this.workGroupId = "9";
          onPhoneIds = this.workGroup10;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "10") {
          this.workGroupId = "10";
          onPhoneIds = this.workGroup11;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "11") {
          this.workGroupId = "11";
          onPhoneIds = this.workGroup12;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "12") {
          this.workGroupId = "12";
          onPhoneIds = this.workGroup13;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "13") {
          this.workGroupId = "13";
          onPhoneIds = this.workGroup14;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup15,
            this.workGroup16
          );
        } else if (this.workGroupName == "14") {
          this.workGroupId = "14";
          onPhoneIds = this.workGroup15;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup16
          );
        } else if (this.workGroupName == "15") {
          this.workGroupId = "15";
          onPhoneIds = this.workGroup16;
          offPhoneIds = this.workGroup1.concat(
            this.workGroup2,
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15
          );
        } else {
          this.workGroupId = "0";
          onPhoneIds = this.workGroup1;
          offPhoneIds = this.workGroup2.concat(
            this.workGroup3,
            this.workGroup4,
            this.workGroup5,
            this.workGroup6,
            this.workGroup7,
            this.workGroup8,
            this.workGroup9,
            this.workGroup10,
            this.workGroup11,
            this.workGroup12,
            this.workGroup13,
            this.workGroup14,
            this.workGroup15,
            this.workGroup16
          );
        }
      }

      let offData = {
        PhoneIds: offPhoneIds,
      };
      try {
        const res = await this.$store
          .dispatch("group/poweroffphone", { ...offData })
          .then((res) => {
            if (res.RetCode == 0) {
              this.$message.success("关闭成功");
            } else {
              this.$message.error("关闭失败！");
            }
          });
      } catch (error) {}

      let onData = {
        PhoneIds: onPhoneIds,
        TeamGroupId: this.workGroupId,
        MatrixGroupIds: this.matrixGroupIds,
      };
      try {
        const res = await this.$store
          .dispatch("group/poweronphone", { ...onData })
          .then((res) => {
            if (res.RetCode == 0) {
              this.$message.success("开启成功");
              this.$emit("confirmedit");
            } else {
              this.$message.error("开启失败！");
              this.$emit("confirmedit");
            }
          });
      } catch (error) {}
      this.closeworkgroupchange();
    },
    closeworkgroupchange() {
      this.$emit("closeworkgroupchange");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }

  .tips {
    margin: 36px 0 0 14px;
    color: #a1a1a1;
    font-size: 13px;
  }
}
</style>